import React, { useState, useContext, useEffect } from 'react';
import { ResourceView } from "../ClusterDrawer/ClusterDrawer";
import * as yaml from "js-yaml";
import CodeOutlinedIcon from '@material-ui/icons/CodeOutlined';
import DomainIcon from "@material-ui/icons/Domain";
import ComputerIcon from '@material-ui/icons/Computer';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { APIS, GATEWAYS, CUSTOM_DOMAINS, CONFIGMAPS } from "../../utils";
import ResourceTable from "../../components/ResourceTable/ResourceTable";
import OnBoarding from "../OnBoarding/OnBoarding";
import OverviewPage from "../Center/Overview";
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import { TablePaginationConfig } from 'antd';
import { APIRecordFields } from '../../components/ResourceTable/APIsResourceTable';
import { apolloClient } from "../../apolloClient";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { Color } from '@material-ui/lab/Alert';
import {
    gql,
    useLazyQuery,
} from "@apollo/client";
import { Layout } from "antd";
import LeftPane from "../LeftPane/LeftPane"
import CGAppContext from "../../AppContext";
import EdgeOverview from '../Edge/EdgeOverview';
import "./ResourceManagement.scss";

const { Content } = Layout;

const APIs = "APIs";
const CENTER = "Center";
const CDs = "Custom Domains";
const OnBoardingForm = "On Boarding";
const Gateways = "Gateways";
const Overview = "Landscape";

export interface IEdge {
    name: string,
    namespace: string,
    infraSecret: string,
    glooSecretName: string,
    glooSecretNamespace: string,
    filters?: any[],
    gateway?: IGateway,
    charts: IChart[],
    conditions: any[],
    'current-context'?: string,
    kubeconfig?: string,
    deployments?: any[],
}

interface IChart {
    name: string,
    namespace: string,
    releaseName: string,
    releaseStatus?: string,
    releaseStatusDescription?: string,
    version?: string,
}

export interface IGateway {
    name: string,
    namespace: string,
    labels: any,
    class: string,
    configSecretName: string,
    configSecretNamespace: string,
    online: boolean,
    edge?: IEdge,
}

type MessageProps = {
    title: string,
    severity: Color,
};

export enum ViewType {
    VISUAL,
    DETAILED,
}

export const queryEdgeSecrets = gql`
query FetchEdgeSecrets($namespace: String!) {
    ioK8sApiCoreV1SecretList(namespace: $namespace) {
        items {
            metadata {
                name
                namespace
            }
            data
        }
    }
}
`;

export const queryEdgeDeployments = gql`
query FetchEdgeDeployments($namespace: String!) {
    listAppsV1NamespacedDeployment(namespace: $namespace) {
        items {
            metadata {
                name
                namespace
            }
            status {
                readyReplicas
            }
        }
    }
}
`;

export const queryEdge = gql`
query FetchVectorPods($namespace: String!) {
    listAppsV1NamespacedPod(namespace: $namespace) {
        items {
            metadata {
                name
                namespace
            }
            status {
                conditions {
                    lastTransitionTime
                    status
                    type
                }
            }
        }
    }
}
`;

const federatedApiQuery = `
metadata {
    name
    namespace
    ownerReferences {
        name
    }
}
spec {
    placement {
        gateways
    }
    template {
        certificateIssuer
        dnsName
        dnsProvider
        routes {
            targetMulti {
                weightedUpstreams {
                    upstream {
                        hosts {
                            address
                            port
                        }
                        name
                    }
                    weight    
                }
            }
            targetSingle {
                upstream {
                    hosts {
                        address
                        port
                    }
                    name
                }
            }
        }
    }
}
status {
    conditions {
        reason
        status
        type
        message
    }
    placement {
        actual
        desired
        gatewayPlacement
    }
}`;

const apiQuery = `
metadata {
    name
    namespace
}
spec {
    source {
        domain
        hostPrefix
    }
    target {
        host
        port
    }
    routes {
        targetMulti {
            weightedUpstreams {
                upstream {
                    hosts {
                        address
                        port
                    }
                    name
                }
                weight    
            }
        }
        targetSingle {
            upstream {
                hosts {
                    address
                    port
                }
                name
            }
        }
    }
    gatewaySelector
}
status {
    conditions {
        message
        reason
        status
    }
}`;

const customDomainQuery = `
metadata {
    name
    namespace
    labels
}
spec {
    domain
}
status {
    resources {
        name
        state
    }
    conditions {
        lastTransitionTime
        message
        reason
        status
        type
    }
}
`;

const gatewayQuery = `
metadata {
    name
    namespace
    labels
}
spec {
    class
    configSecret {
        name
        namespace
    }
}
status {
    class
}
`;

export const fetchNamespacedResources = gql`
query FetchNamespacedResources($namespace: String!) {
    apis: listCoreApigwBtpSapComV1alpha1NamespacedAPI(namespace: $namespace) {
        items {
            ${apiQuery}
        }
    }
    federatedApis: listCoreApigwBtpSapComV1alpha1NamespacedFederatedAPI(namespace: $namespace) {
        items {
            ${federatedApiQuery}
        }
    }
    gateways: comSapBtpApigwCoreV1alpha1GatewayList {
        items {
            ${gatewayQuery}
        }
    }
    customDomains: listCoreApigwBtpSapComV1alpha1NamespacedCustomDomain(namespace: $namespace) {
        items {
            ${customDomainQuery}
        }
    }
}
`;

// this might fail in case the K8s service account doesn't
// have permissions to get resources across all namespaces
export const fetchAllResources = gql`
query FetchAllResources {
    apis: comSapBtpApigwCoreV1alpha1APIList {
        items {
            ${apiQuery}
        }
    }
    federatedApis: comSapBtpApigwCoreV1alpha1FederatedAPIList {
        items {
            ${federatedApiQuery}
        }
    }
    gateways: comSapBtpApigwCoreV1alpha1GatewayList {
        items {
            ${gatewayQuery}
        }
    }
    customDomains: comSapBtpApigwCoreV1alpha1CustomDomainList {
        items {
            ${customDomainQuery}
        }
    }
    configmaps: ioK8sApiCoreV1ConfigMapList {
        items {
            metadata {
                name
                namespace
                labels
            }
            data
        }
    }
    centerStackList: comSapApigwV1alpha1CenterSWStackList {
        items {
            metadata {
                name
                namespace
                creationTimestamp
            }
            spec {
                stack {
                    name
                    namespace
                    path
                    valuesSecretRef {
                        name
                        namespace
                    }
                }
            }
            status {
                chartStatus {
                    name
                    namespace
                    releaseStatus
                    releaseStatusDescription
                    version
                }
                conditions {
                    lastTransitionTime
                    message
                    reason
                    status
                    type
                }
            }
        }
    }
    comSapApigwV1alpha1EdgeSWStackList {
        items {
            metadata {
                name
                namespace
            }
            spec {
                infraAuthSecretRef {
                    name
                }
                stack {
                    name
                    namespace
                    path
                    valuesSecretRef {
                        name
                        namespace
                    }
                }
            }
            status {
                chartStatus {
                    name
                    namespace
                    releaseStatus
                    releaseStatusDescription
                    version
                }
                conditions {
                    lastTransitionTime
                    message
                    reason
                    status
                    type
                }
            }
        }
    }
}
`;

interface ResourceManagementProps {
    shouldPoll: boolean,
    newApiKeys: any,
}

enum RecordFields {
    name = "name",
    namespace = "namespace",
    domain = "domain",
    certIssuerStatus = "cert-issuer-status",
    dnsProviderStatus = "dns-provider-status",
    readyStatus = "ready-status",
    labels = "labels",
    class = "class",
    managed = "managed",
    online = "online",
    releaseStatus = "release-status",
}

// These IDs are used to construct the requests to the server APIs!
export enum ResourceViewID {
    overview = "overview",
    api = "api",
    customdomains = "customDomain",
    gateways = "gateway",
    onboarding = "onboarding"
}

let apis: any[] = [];

export default function ResourceManagement(props: ResourceManagementProps) {
    const appContext = useContext(CGAppContext);
    const [open, setOpen] = React.useState(false);
    const [messageProps, setMessageProps] = React.useState<MessageProps>({
        title: '',
        severity: 'success',
    });

    const resourcesViews: ResourceView[] = [
        {
            id: ResourceViewID.overview,
            name: Overview,
            icon: <DashboardIcon />,
            data: [],
            configurableProps: [],
            editableProps: []
        },
        {
            id: ResourceViewID.gateways,
            name: Gateways,
            icon: <ComputerIcon />,
            data: [],
            configurableProps: [],
            editableProps: [
                RecordFields.labels,
                RecordFields.class
            ]
        },
        {
            id: ResourceViewID.customdomains,
            name: CDs,
            icon: <DomainIcon />,
            data: [],
            configurableProps: [
                RecordFields.name,
                RecordFields.namespace,
                RecordFields.domain
            ],
            editableProps: [
                RecordFields.domain
            ]
        },
        {
            id: ResourceViewID.api,
            name: APIs,
            icon: <CodeOutlinedIcon />,
            data: [],
            configurableProps: [
                APIRecordFields.name,
                APIRecordFields.namespace,
                APIRecordFields.sourcePrefix,
                APIRecordFields.sourceDomain,
                APIRecordFields.targetHost,
                APIRecordFields.targetPort
            ],
            editableProps: [
                APIRecordFields.sourcePrefix,
                APIRecordFields.sourceDomain,
                APIRecordFields.targetHost,
                APIRecordFields.targetPort
            ]
        }

    ];

    const [currentView, setCurrentView] = React.useState<ResourceView>(resourcesViews[0]);
    const [currentGateway, setCurrentGateway] = React.useState<string>('');
    const [viewType, setViewType] = React.useState<ViewType>(ViewType.VISUAL);
    const [data, setData] = React.useState<any>();
    const [currentEdge, setCurrentEdge] = React.useState<any>();

    // Table state regarding filter, sort, pagination
    const [tableState, setTableState] = useState({});
    const [edges, setEdges] = useState<Map<string, IEdge>>(new Map());
    const [gateways, setGateways] = useState<IGateway[]>([]);

    // Selection state
    const [tableSelection, setTableSelection] = useState({
        selectedRowKeys: [],
        selectedRows: []
    });

    function getGatewayApis(gatewayName: string) {
        const allApis: any[] = mapData(APIS, data);

        const gatewayApis: any[] = allApis.filter((api) => {
            return api.gateways.includes(gatewayName);
        });

        return gatewayApis;
    }

    function onRowSelectionChange(selectedRowKeys: any, selectedRows: any) {
        setTableSelection({
            selectedRowKeys,
            selectedRows
        });
    }

    function onTableChange(pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<any> | SorterResult<any>[], extra: TableCurrentDataSource<any>) {
        setTableState({
            filteredInfo: filters,
            sortedInfo: sorter,
        });
    }

    async function refreshTable() {
        if (refetch) {
            refetch();
        }
    }

    // TODO: FIX: When some dialog is opened like CREATE, polling closes the dialog!!!
    // Call update data every 10 seconds
    // useInterval(async () => {
    //     await updateData()
    // }, 10 * 1000);

    const setGateway = (name: string) => {
        setCurrentGateway(name);
    };

    const showGateway = (name: string, viewType: ViewType) => {
        setViewType(viewType);

        setCurrentGateway(name);

        const gatewaysView: ResourceView | undefined = resourcesViews.find(view => view.name === GATEWAYS);
        if (gatewaysView) {
            setCurrentView(gatewaysView);
        }
    }

    const handleChangeViewType = (viewType: ViewType) => {
        setViewType(viewType);
    };

    const handleResourceListChange = async (resName: string, filteredInfo?: any[]) => {
        if (resName === Overview) {
            setCurrentGateway('');
        }

        const newView = resourcesViews.find(view => view.name === resName)
        if (newView !== undefined) {
            setCurrentView(newView)
        }

        // Clear filters and sorters
        setTableState({
            filteredInfo: filteredInfo,
            sortedInfo: null
        });

        // Clear selection when switching between tables  
        setTableSelection({
            selectedRowKeys: [],
            selectedRows: []
        });
    }

    const onEdgeDeploymentsReady = async function (this: any, edgeDeployments: any) {
        const edge: IEdge | undefined = edges.get(this.edgeName);
        if (edge && edgeDeployments?.listAppsV1NamespacedDeployment?.items) {
            edge.deployments = edgeDeployments?.listAppsV1NamespacedDeployment?.items.map((value: any) => {
                return {
                    name: value.metadata.name,
                    namespace: value.metadata.namespace,
                    status: (value.status.readyReplicas > 0 ? 'Ready' : 'Not ready'),
                }
            });
        }
    }

    const onEdgeSecretsReady = async (edgeSecrets: any) => {
        for (const edge of Array.from(edges.values())) {
            const name: string = edge.name;
            const namespace: string = edge.namespace;

            const secrets = edgeSecrets.ioK8sApiCoreV1SecretList?.items;
            const infraSecret = secrets.find((value: any) => {
                return edge.infraSecret === value.metadata?.name && namespace === value.metadata?.namespace;
            });

            const glooSecret = secrets.find((value: any) => {
                return edge.glooSecretName === value.metadata?.name && edge.glooSecretNamespace === value.metadata?.namespace;
            });

            try {
                const kubeconfigEncoded: string = infraSecret?.data.kubeconfig;
                const kubeconfigString: string = atob(kubeconfigEncoded);
                const kubeconfig: any = yaml.load(kubeconfigString);
                edge.kubeconfig = kubeconfigString;
                edge['current-context'] = kubeconfig['current-context'];

                setCurrentEdge({ edgeName: name, kubeconfig: kubeconfigEncoded });
                getEdgeDeployments(
                    {
                        variables: {
                            namespace: 'apigw-gloo-ee'
                        },
                    },
                );

            } catch (e) {
                console.error(e);
            }

            try {
                const glooValuesEncoded: string = glooSecret?.data.values;
                const glooValuesString: string = atob(glooValuesEncoded);
                const glooValues: any = yaml.load(glooValuesString);
            } catch (e: any) {
                console.warn(`Couldn't get gloo values: ${e.message}`);
            }
        }
    };

    const [getEdgeDeployments] = useLazyQuery(queryEdgeDeployments, {
        client: apolloClient,
        errorPolicy: 'all',
        onCompleted: onEdgeDeploymentsReady.bind(currentEdge),
        context: {
            headers: {
                "centralgateway-kubeconfig": currentEdge?.kubeconfig,
                "cluster-type": "edge",
            },
        },
    });

    /**
     TODO:
     * 1. run this query after fetchAllResources is complete (use relevant namespace(s) as query variable)
     * 2. match secret to edge stack (edge stack .spec.infraAuthSecretRef.name and in respective namespace)
     * 3. base64-decode the secret's data.kubeconfig field and show the current-context and url (clusters[0].cluster.server) in UI
     * */
    const [getEdgeSecrets] = useLazyQuery(queryEdgeSecrets, {
        client: apolloClient,
        errorPolicy: 'all',
        onCompleted: onEdgeSecretsReady,
    });

    const onResourcesReady = async (dataParam: any): Promise<void> => {
        if (dataParam?.gateways === null) {
            showMessage({ title: `There seems to be an issue with this landscape. The cluster may be hibernating or the cluster's credentials may have been rotated. Try waking up the cluster or providing a fresh kubeconfig file.`, severity: 'error' });
        }
        apis = dataParam.apis?.items;
        setData(dataParam);
        const gatewayLabels = getGatewayLabels(dataParam);
        appContext.gatewayLabels = gatewayLabels;

        const customDomains: any[] = mapData(CUSTOM_DOMAINS, dataParam);
        appContext.customDomains = customDomains;

        const _gateways: IGateway[] = mapData(GATEWAYS, dataParam);
        setGateways(_gateways);

        const secretsNamespaces = new Set();
        const newEdges: Map<string, IEdge> = new Map();
        if (dataParam.comSapApigwV1alpha1EdgeSWStackList) {
            for (const edgeSWStack of dataParam.comSapApigwV1alpha1EdgeSWStackList.items) {
                secretsNamespaces.add(edgeSWStack.metadata.namespace);
                const stacks = (edgeSWStack as any).spec?.stack;
                const glooStack = stacks?.find((value: any) => {
                    return value.name === 'apigw-gloo-ee';
                });
                const glooStackSecretName: string = glooStack?.valuesSecretRef.name;
                const glooStackSecretNamespace: string = glooStack?.valuesSecretRef.namespace;
                // secretsNamespaces.add(glooStackSecretNamespace);
                const name: string = edgeSWStack.metadata.name;

                const charts: IChart[] = edgeSWStack.status?.chartStatus;
                const conditions: any[] = edgeSWStack.status?.conditions;
                const edge: IEdge = {
                    name,
                    namespace: edgeSWStack.metadata.namespace,
                    infraSecret: edgeSWStack.spec?.infraAuthSecretRef?.name,
                    glooSecretName: glooStackSecretName,
                    glooSecretNamespace: glooStackSecretNamespace,
                    charts,
                    conditions,
                }

                const gateway = _gateways?.find((value) => {
                    return value.configSecretNamespace === edge.namespace &&
                        value.configSecretName === edge.infraSecret;
                });
                if (gateway) {
                    gateway.edge = edge;
                }

                newEdges.set(name, edge);
            }
        }

        setEdges(newEdges);
        const secretsNamespacesArray: any[] = Array.from(secretsNamespaces.keys());
        for (const namespace of secretsNamespacesArray) {
            getEdgeSecrets({ variables: { namespace } });
        }
    };

    let resourcesQuery;
    let namespace;
    if (appContext.cluster?.namespace) {
        resourcesQuery = fetchNamespacedResources;
        namespace = appContext.cluster?.namespace;
    } else {
        resourcesQuery = fetchAllResources;
    }

    const [fetchResources, { refetch }] = useLazyQuery(resourcesQuery, {
        client: apolloClient,
        errorPolicy: 'ignore',
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onCompleted: onResourcesReady,
        variables: { namespace },
    });

    useEffect(() => {
        fetchResources();
    }, []);

    useEffect(() => {
        if (props.shouldPoll) {
            pollUntilAdded(props.newApiKeys);
        }
    }, [props.shouldPoll]);

    function getGatewayLabels(data: any): Map<string, any> {
        const response: Map<string, any> = new Map();

        const gateways: any[] = mapData(GATEWAYS, data);
        if (gateways) {
            for (const gateway of gateways) {
                const labels: any = gateway.labels;
                for (const key in labels) {
                    response.set(`${key}-${labels[key]}`, { key, value: labels[key] });
                }
            }
        }
        return response;
    }

    function showMessage(messageProps: MessageProps) {
        setMessageProps(messageProps);
        setOpen(true);
    }

    const closeMessage = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    function pollUntilAdded(variables: any) {
        const startTime: number = new Date().getTime();

        const handle = setInterval(() => {
            const currentTime: number = new Date().getTime();
            let showedCreationMessage: boolean = false;

            const foundAPI = apis.find((value: any) => {
                return value.metadata.namespace === variables.namespace && value.metadata.name === variables.name;
            });

            if (foundAPI && !showedCreationMessage) {
                showedCreationMessage = true;
                showMessage({
                    title: `API ${variables.name} in namespace ${variables.namespace} was created and is being published`,
                    severity: 'success',
                });
            }

            if (currentTime - startTime > 30000 && !foundAPI) {
                // Timeout: API wasn't created
                showMessage({
                    title: `Timeout: API ${variables.name} in namespace ${variables.namespace} wasn't created`,
                    severity: 'error'
                });
                console.error(`Timeout: API ${variables.name} in namespace ${variables.namespace} wasn't created`);
                clearInterval(handle);
            }

            if (foundAPI && foundAPI.status?.conditions?.length > 0) {
                const publishedStatus = foundAPI.status.conditions[0].status;
                const publishedReason = foundAPI.status.conditions[0].reason;
                const publishedMessage = foundAPI.status.conditions[0].message;
                if (currentTime - startTime > 60000 &&
                    publishedStatus === 'False'
                ) {
                    // Timeout: API isn't ready
                    showMessage({
                        title: `Timeout: API ${variables.name} in namespace ${variables.namespace} isn't ready`,
                        severity: 'warning',
                    });
                    console.error(`Timeout: API ${variables.name} in namespace ${variables.namespace} isn't ready`);
                    clearInterval(handle);
                } else if (publishedStatus === 'True') {
                    // API is ready
                    showMessage({
                        title: `API ${variables.name} in namespace ${variables.namespace} was created successfully`,
                        severity: 'success'
                    });
                    console.info(`API ${variables.name} in namespace ${variables.namespace} was created successfully`);
                    clearInterval(handle);
                } else if (publishedStatus === 'False' && publishedReason !== 'PublishInProgress') {
                    // API failed to publish
                    showMessage({
                        title: `${foundAPI.metadata.name}: ${publishedReason}${publishedMessage}`,
                        severity: 'error'
                    });
                    console.error(`${foundAPI.metadata.name}: ${publishedReason}${publishedMessage}`);
                    clearInterval(handle);
                }
            }

            refreshTable();
        }, 1000);
    }

    function allMappedData(data: any) {
        const result = {
            center: mapData(CENTER, data),
            gateway: mapData(GATEWAYS, data),
            customDomain: mapData(CUSTOM_DOMAINS, data),
            api: mapData(APIS, data),
            configmaps: mapData(CONFIGMAPS, data),
        }
        return result;
    }

    function mapData(viewName: string, data: any): any {
        if (!data) {
            return;
        }

        const currentLob: string | undefined = appContext.cluster.lob;

        let map: any;
        switch (viewName) {
            case APIS:
                const gateways: any[] = mapData(GATEWAYS, data);
                const domains: any[] = mapData(CUSTOM_DOMAINS, data);
                map = [];
                if (data.apis) {
                    for (const item of data.apis.items) {
                        // find custom domain
                        const domain = domains.find((value) => {
                            return value.domain === item.spec?.source?.domain;
                        });

                        const federatedApis = data.federatedApis?.items;
                        const federatedApi = federatedApis?.find((value: any) => {
                            return value.metadata?.ownerReferences[0]?.name === item.metadata.name;
                        });

                        if (domain) {
                            // find matching gateways based on selector
                            const matchingGateways = gateways.filter((value) => {
                                let foundMatch: boolean = false;
                                let foundMismatch: boolean = false;
                                for (const selector in item.spec.gatewaySelector) {
                                    if (value.labels[selector] === item.spec.gatewaySelector[selector]) {
                                        foundMatch = true;
                                    } else {
                                        foundMismatch = true;
                                        break;
                                    }
                                }
                                return foundMatch && !foundMismatch;
                            });
                            const matchingGatewayNames: string[] = matchingGateways.map((gateway) => gateway?.name);

                            const api: any = {
                                name: item.metadata.name,
                                namespace: item.metadata.namespace,
                                "source-domain": item.spec.source.domain,
                                "source-prefix": item.spec.source.hostPrefix,
                                "target-host": item.spec.target?.host,
                                "target-port": item.spec.target?.port,
                                routes: item.spec.routes,
                                "gateway-selectors": item.spec.gatewaySelector,
                                "published-status": item.status.conditions[0]?.status,
                                "published-message": item.status.conditions[0]?.message,
                                "published-reason": item.status.conditions[0]?.reason,
                                "dns-status": domain["dns-provider-status"],
                                gateways: matchingGatewayNames,
                                "certificate-status": domain["cert-issuer-status"],
                                lob: domain.lob,
                            }
                            if (federatedApi) {
                                api["certificate-issuer"] = federatedApi.spec.template?.certificateIssuer;
                                api["dns-name"] = federatedApi.spec.template?.dnsName;
                                api["dns-provider"] = federatedApi.spec.template?.dnsProvider;
                                api["federated-conditions"] = federatedApi.status?.conditions;
                                api["federated-status"] = federatedApi.status?.placement?.gatewayPlacement;
                                api["actual"] = federatedApi.status?.placement?.actual;
                                api["desired"] = federatedApi.status?.placement?.desired;
                            }
                            map.push(api);
                        }
                    }
                }
                break;
            case GATEWAYS:
                map = data.gateways?.items?.map((item: any) => {
                    return {
                        name: item.metadata.name,
                        namespace: item.metadata.namespace,
                        labels: item.metadata.labels,
                        class: item.status.class,
                        configSecretName: item.spec.configSecret.name,
                        configSecretNamespace: item.spec.configSecret.namespace,
                        online: true,
                    }
                });
                break;
            case CONFIGMAPS:
                if (data.configmaps) {
                    const featureToggleConfigMaps: any[] = data.configmaps.items?.filter((item: any) => {
                        return (item.metadata?.labels?.sapComMainFeatureToggleDefinition === "true" ||
                        item.metadata?.labels?.sapComMainFeatureToggleDefinition === true);
                    });

                    map = featureToggleConfigMaps.map((item: any) => {
                        return {
                            name: item.metadata.name,
                            namespace: item.metadata.namespace,
                            labels: item.metadata.labels,
                            features: item.data?.featuresJson,
                        }
                    });
                }
                break;
            case CUSTOM_DOMAINS:
                map = [];
                if (data.customDomains) {
                    for (const item of data.customDomains.items) {
                        if (!currentLob || (currentLob && currentLob === item.metadata.labels?.lob)) {
                            const resourceStatuses = item.status.resources;
                            const dnsState = resourceStatuses.find((value: any) => {
                                return value.name === 'dns-provider';
                            });
                            const certState = resourceStatuses.find((value: any) => {
                                return value.name === 'cert-issuer';
                            });

                            const dnsStatus: boolean = (dnsState?.state === 'Ready') || false;
                            const certStatus: boolean = (certState?.state === 'Ready') || false;

                            let readyStatus: boolean = false;
                            const conditions: any[] = item.status.conditions;
                            if (conditions && conditions.length > 0) {
                                conditions.sort((a: any, b: any) => {
                                    return a.lastTransitionTime > b.lastTransitionTime ? -1 : 1;
                                });
                                readyStatus = conditions[0].status === 'True' || conditions[0].type === 'Ready';
                            }
                            map.push({
                                name: item.metadata.name,
                                namespace: item.metadata.namespace,
                                domain: item.spec?.domain,
                                lob: item.metadata.labels?.lob,
                                "cert-issuer-status": certStatus,
                                "dns-provider-status": dnsStatus,
                                "ready-status": readyStatus,
                            });
                        }
                    }
                }
                break;
            case CENTER:
                map = {};
                if (data.centerStackList) {
                    let charts = [];
                    for (const stack of data.centerStackList.items) {
                        const centerCharts = (stack as any).status?.chartStatus;
                        let centerChartsMap = centerCharts.map((item: any) => {
                            return {
                                "stack-name": stack.metadata.name,
                                namespace: item.namespace,
                                name: item.name,
                                version: item.version,
                                "release-status": item.releaseStatus,
                                "release-status-description": item.releaseStatusDescription,
                            }
                        });
                        charts.push(...centerChartsMap);
                        const conditions: any[] = stack.status?.conditions;

                        map.conditions = conditions;
                        map.charts = charts;
                        map.name = stack.metadata.name;
                        map.namespace = stack.metadata.namespace;
                        map.creationTimestamp = stack.metadata.creationTimestamp;
                    }
                }

                break;
        }
        return map
    }

    return (
        <Layout className="ResourceManagement">
            <LeftPane currentView={currentView.name} list={resourcesViews} gateways={gateways} cluster={appContext.cluster}
                clusterConnected={true} clusterIconPath="TBD" onClickListItem={handleResourceListChange} onSetGateway={setGateway}
                selectedGateway={currentGateway}
            />
            <Layout className="FullHeight site-layout">
                {currentView.name === OnBoardingForm &&
                    <OnBoarding />
                }

                {currentView.name === Overview &&
                    <OverviewPage
                        data={allMappedData(data)}
                        gateways={gateways}
                        showGateway={showGateway} />
                }

                {currentView.name === GATEWAYS &&
                    <EdgeOverview gateway={gateways.find((gw) => { return currentGateway === gw.name })} apis={getGatewayApis(currentGateway)} onChangeViewType={handleChangeViewType} />
                }

                {currentView.name !== Overview && currentView.name !== OnBoardingForm &&
                    currentView.name !== GATEWAYS && currentView.name !== CENTER &&

                    <Content style={{ minHeight: "100%", margin: '0 8px', overflowY: 'auto', overflowX: 'hidden' }}>
                        <ResourceTable
                            listName={currentView.name}
                            list={mapData(currentView.name, data)}
                            currentView={currentView}
                            handleResourceListChange={handleResourceListChange}
                            tableState={tableState}
                            tableSelection={tableSelection}
                            onRowSelectionChange={onRowSelectionChange}
                            onTableChange={onTableChange}
                            refreshTable={refreshTable}
                        />
                    </Content>
                }
            </Layout>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={open}
                autoHideDuration={messageProps.severity === 'error' ? 12000 : 6000}
                onClose={closeMessage}
            >
                <MuiAlert onClose={closeMessage} severity={messageProps.severity}>
                    {messageProps.title}
                </MuiAlert>
            </Snackbar>
        </Layout>
    );
}