import config from './config';
import {
    ApolloClient,
    ApolloLink,
    InMemoryCache,
    createHttpLink,
    from,
} from "@apollo/client";
import * as auth from "./services/auth.service";

import { setHeaders } from "./axios-instance";

let newHeaders: any = {};

async function setAuth(options: any) {
    const kubeconfig: string = options.kubeconfig;
    const kubecontext: string = options.kubecontext;
    // const user = await Auth.currentAuthenticatedUser();
    // const token = user.signInUserSession.idToken.jwtToken;
    const token = auth.getAccessToken();

    newHeaders = {
        authorization: token ? `Bearer ${token}` : "",
    }
    if (kubeconfig) {
        newHeaders["centralgateway-kubeconfig"] = kubeconfig;
        setHeaders([{ key: "centralgateway-kubeconfig", value: kubeconfig }]);
    }
    if (kubecontext) {
        newHeaders["centralgateway-kubecontext"] = kubecontext;
        setHeaders([{ key: "centralgateway-kubecontext", value: kubecontext }]);
    }

    const httpLink = createHttpLink({
        uri: `${config.api.invokeUrl}/graphql`,
        headers: newHeaders,
        // credentials: 'include',
    });

    apolloClient.setLink(from([customLink, httpLink]));
}

const customLink = new ApolloLink((operation, forward) => {
    // TODO: should use this approach to combine authentication header with center cluster or edge cluster headers
    // console.log(newHeaders);
    // console.log(operation.operationName, operation.getContext());
    return forward(operation);
});

const httpLink = createHttpLink({
    uri: `${config.api.invokeUrl}/graphql`,
    // credentials: 'include',
});

const apolloClient = new ApolloClient({
    link: from([customLink, httpLink]),
    cache: new InMemoryCache(),
});

export {
    apolloClient,
    setAuth
};
