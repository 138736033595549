import {Typography, Table, Divider, Row, Col, Tooltip, TablePaginationConfig} from 'antd';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {IGateway, ViewType} from '../ResourceManagement/ResourceManagement';
import ResourceTable from '../../components/ResourceTable/ResourceTable';
import {APIS} from '../../utils';
import {useState} from 'react';
import {FilterValue, SorterResult, TableCurrentDataSource} from 'antd/lib/table/interface';

const {Title, Text} = Typography;

type Props = {
    gateway?: IGateway;
    onChangeViewType: any;
    apis?: any;
}

const chartColumns: any = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Version',
        dataIndex: 'version',
        key: 'version',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
    },
];

const chartDeploymentColumns: any = [
    {
        title: 'Time',
        dataIndex: 'time',
        key: 'time',
    },
    {
        title: 'Reason',
        dataIndex: 'reason',
        key: 'reason',
    },
    {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
    },
    {
        title: 'Info',
        dataIndex: 'info',
        key: 'info',
        render: (text: string, record: any) => (
            record.message && <Tooltip placement="topLeft" title={record.message}>
                <InfoOutlinedIcon/>
            </Tooltip>
        ),
    }
];

const k8sDeploymentColumns: any = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Namespace',
        dataIndex: 'namespace',
        key: 'namespace',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
    },
];

export default function Edge(props: Props) {
    const [tableState, setTableState] = useState({});
    const [tableSelection, setTableSelection] = useState({
        selectedRowKeys: [],
        selectedRows: []
    });

    function onTableChange(pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<any> | SorterResult<any>[], extra: TableCurrentDataSource<any>) {
        setTableState({
            filteredInfo: filters,
            sortedInfo: sorter,
        });
    }

    function getDefaultActiveKey(): string {
        if (props?.gateway?.edge?.filters) {
            return props?.gateway?.edge?.filters[0]?.name
        } else {
            return '';
        }
    }

    return (

        <Row className={"DetailsRow"}>
            <Col span={24}>
                <Divider orientation="left">Gateway Properties</Divider>
                <Row gutter={16}>
                    <Col span={6}>
                        <Title level={5}>Namespace</Title>
                        <Text>{props.gateway?.namespace}</Text>
                    </Col>
                    <Col span={6}>
                        <Title level={5}>Region</Title>
                        <Text>{props.gateway?.labels?.region}</Text>
                    </Col>
                    <Col span={6}>
                        <Title level={5}>Class</Title>
                        <Text>{props.gateway?.class}</Text>
                    </Col>
                </Row>
            </Col>
            {props.gateway?.edge ? <>
                    <Col span={24}>
                        <Divider orientation="left">Edge Properties</Divider>
                        <Row gutter={16}>
                            <Col span={6}>
                                <Title level={5}>Name</Title>
                                <Text>{props.gateway?.edge?.name}</Text>
                            </Col>
                            <Col span={6}>
                                <Title level={5}>Namespace</Title>
                                <Text>{props.gateway?.edge?.namespace}</Text>
                            </Col>
                            <Col span={6}>
                                <Title level={5}>Kubernetes Context</Title>
                                <Text>{(props.gateway?.edge && props.gateway?.edge['current-context'])}</Text>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Divider orientation="left">Chart Deployment Status</Divider>
                        <Table size="small" columns={chartDeploymentColumns} pagination={false}
                               dataSource={props.gateway?.edge?.conditions?.map((condition) => {
                                   return {
                                       key: `${condition.lastTransitionTime}-${condition.type}`,
                                       time: `${new Date(condition.lastTransitionTime)}`,
                                       reason: condition.reason,
                                       type: condition.type,
                                       message: condition.message,
                                   }
                               })}/>
                    </Col>
                    <Col span={24}>
                        <Divider orientation="left">Charts</Divider>
                        <Table size="small" columns={chartColumns} pagination={false} dataSource={props.gateway?.edge?.charts?.map((chart) => {
                            return {
                                key: `${chart.namespace}-${chart.name}`,
                                name: chart.name,
                                version: chart.version,
                                status: chart.releaseStatusDescription,
                            }
                        })}/>
                    </Col>
                    <Col span={24}>
                        <Divider orientation="left">Kubernetes Deployments</Divider>
                        <Table size="small" columns={k8sDeploymentColumns} pagination={false}
                               dataSource={props.gateway?.edge?.deployments?.map((deployment) => {
                                   return {
                                       key: `${deployment.namespace}-${deployment.name}`,
                                       name: deployment.name,
                                       namespace: deployment.namespace,
                                       status: deployment.status,
                                   }
                               })}/>
                    </Col>
                    <Col span={24}>
                        <Divider orientation="left">APIs on '{props.gateway?.name}' gateway</Divider>
                        <ResourceTable
                            listName={APIS}
                            list={props.apis}
                            currentView={APIS}
                            handleResourceListChange={() => {
                            }}
                            tableState={tableState}
                            tableSelection={tableSelection}
                            onRowSelectionChange={() => {
                            }}
                            onTableChange={onTableChange}
                            refreshTable={() => {
                            }}
                            readonly={true}
                            currentGateway={props.gateway?.name}
                        />

                    </Col>
                </> :
                <div></div>
            }
        </Row>
    );
}
