import { Button } from "@material-ui/core";
import CodeOutlinedIcon from '@material-ui/icons/CodeOutlined';
import { APIS } from '../../../utils';
import _ from "lodash";

interface Props {
  selectedRow: any,
  handleResourceListChange: any
}

export default function ToolbarGetAPIsOnGW(props: Props) {


  function handleFilterAPIsOnGW() {

    // Some Gateway is selected
    // Set the filter so the APIs will be filtered by this gateway data
    console.log(`Going to filter by the selected row: ${JSON.stringify(props.selectedRow)}`);

    // Go to APIs page with filter
    const filteredInfo = {
      gateways: [`${props.selectedRow.namespace}/${props.selectedRow.name}`]
    };
    props.handleResourceListChange(APIS, filteredInfo);
  }

  return (<Button
    disabled={_.isEmpty(props.selectedRow)} 
    color="primary"
    size="small"
    startIcon={<CodeOutlinedIcon />}
    onClick={handleFilterAPIsOnGW}
  >APIs on Gateway
  </Button>)
}