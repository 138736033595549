import _ from "lodash";
import { SearchOutlined } from '@ant-design/icons';
import { ColumnFilterItem, FilterDropdownProps } from 'antd/lib/table/interface';
import { Space, Button, Select } from 'antd';

const { Option } = Select;

type CustomFilterMenuProps = {
  filterDropdownProps: FilterDropdownProps,
  listName: string,
  columnName: string
}

export default function CustomFilterMenu(props: CustomFilterMenuProps) {

  const handleSearch = (confirm: any) => {
    confirm();
    if (_.isEmpty(props.filterDropdownProps.selectedKeys[0])) {
      handleReset(props.filterDropdownProps.clearFilters);
    }
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
  };

  return (
    <div style={{ padding: 8 }}>
      <Select
        mode="tags"
        showSearch
        style={{ marginBottom: 8, display: 'block', width: 300 }}
        placeholder="Search to Select"
        value={props.filterDropdownProps.selectedKeys[0]}
        onChange={(value: any, optionsArray: any) => props.filterDropdownProps.setSelectedKeys(value ? [value] : [])}
        onInputKeyDown={(event: any) => {
          if (event.key === "Enter") {
            const value = event.target.value;
            props.filterDropdownProps.setSelectedKeys(value ? [value] : []);
            handleSearch(props.filterDropdownProps.confirm);
          }
        }}
        optionFilterProp="children"
      >
        {
          _.map(props.filterDropdownProps.filters, (filterItem: ColumnFilterItem) => {
            return (
              <Option key={filterItem.value.toString()} value={filterItem.value.toString()}> {filterItem.text} </Option>
            )
          })
        }

      </Select>
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(props.filterDropdownProps.confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(props.filterDropdownProps.clearFilters)}
          size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </Space>
    </div>
  )
}