import OverviewOrDetails from "../../components/OverviewOrDetails/OverviewOrDetails";
import VisualEdge from "./VisualEdge";
import Edge from "./Edge";

interface EdgeProps {
    gateway: any;
    onChangeViewType: any;
    apis: any[];
}

function EdgeOverview(props: EdgeProps) {

    return (
        <OverviewOrDetails
            overview={<VisualEdge gateway={props.gateway} onChangeViewType={props.onChangeViewType} apis={props.apis}/>}
            details={<Edge gateway={props.gateway} onChangeViewType={props.onChangeViewType} apis={props.apis}/> }
                />
    );
}

export default EdgeOverview;