import * as React from 'react';
import {Tag} from "antd";
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
} from '@ant-design/icons';
declare type StatusType = true | false | 'True' | 'False';

interface StatusProps {
    status: StatusType;
}

const Status = React.memo((props: StatusProps) => {
    const {status} = props;
    let icon: any = null;
    let label: string = "Ready";
    let color: string = "";
    if (!status || status === 'False') {
        icon = <CloseCircleOutlined />;
        label = "Not Ready";
        color = "error"
    } else if (status || status === 'True') {
        icon = <CheckCircleOutlined />;
        label = "Ready";
        color = "success"
    }

    return (
        <Tag icon={icon} color={color}>
            {label}
        </Tag>
    );
});

export function renderStatus(value: any, record: any, index: number) {
    return <Status status={value as StatusType}/>;
}
