import { Spin } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styles from "./Kubeconfig.module.scss";
import { List, ListItem, Typography, Divider } from "@material-ui/core";
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import { axiosInstance } from '../../axios-instance';
import { Layout, Row, Col } from 'antd';

type Props = {
    onAcceptedKubeconfig: (acceptedFile: any) => void;
    onSetKubeCluster: (context: any) => void;
    isAuthenticated: boolean | null;
};

function Kubeconfig(props: Props) {
    const [clusters, setClusters] = useState<any[] | null>(null);

    useEffect(() => {
        let isMounted = true;
        (async () => {
            try {
                if (props.isAuthenticated) {
                    const data = await axiosInstance.get('/clusters');
                    if (isMounted) {
                        setClusters(data.data);
                    }
                }
            } catch (e) {
                console.error(e);
            }
        })();
        return () => { isMounted = false };
    }, [props.isAuthenticated]);

    const onDrop = useCallback(acceptedFile => {
        props.onAcceptedKubeconfig(acceptedFile);
    }, [props]);

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({ multiple: false, onDrop });
    // TODO: add accept: 'application/x-yaml, text/x-yaml, text/yaml, application/yaml'.
    // My kubeconfiges do not include types therefore not getting accepted.

    return (
        <div className={styles.Wrapper}>
            <Typography variant="h6">Choose a Landscape</Typography>
            <Typography variant="subtitle2">Choose from list or select a kubeconfig file</Typography>
            <Divider />
            <Layout style={{ paddingTop: '0.2rem' }}>
                <Row style={{ height: "100%" }}>
                    <Col span={12}>
                        <Row justify="center" align="middle">
                            <Typography variant="subtitle1">
                                Kubeconfig file
                            </Typography>
                        </Row>
                        <Row justify="center" align="middle" style={{ height: '400px' }}
                        >
                            <Col span="20" className={styles.LocalFileContainer}>
                                <Row justify="center" align="middle" style={{ height: '100%', cursor: 'pointer' }}
                                    {...getRootProps()}
                                >
                                    <Col >
                                        <Row justify="center" align="middle"
                                        >
                                            <input {...getInputProps()} />

                                            <img
                                                alt="kubeconfig-file"
                                                src="/static/images/kubeimg.png"
                                                style={{ height: '150px', cursor: 'pointer' }}
                                            />
                                        </Row>
                                        <Row justify="center">
                                            <Typography variant="subtitle1">
                                                Drag and drop your kubeconfig file
                                            </Typography>
                                        </Row>
                                        <Row justify="center">
                                            <Typography variant="subtitle2">
                                                or click to select file
                                            </Typography>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row justify="center" align="middle">
                            <Typography variant="subtitle1">
                                Recent landscapes
                            </Typography>
                        </Row>
                        <Row justify="center" align="middle" style={{ height: '400px' }}
                        >
                            {clusters ?
                                <div className={styles.ListContainer}>
                                    <List component="nav" aria-label="kubeconfig contexts">
                                        {clusters.map((cluster: any) =>
                                            <ListItem
                                                button
                                                style={{ paddingTop: '0.1rem', paddingBottom: '0.1rem' }}
                                                onClick={(e) => {
                                                    props.onSetKubeCluster(cluster);
                                                }}
                                                key={cluster.context}>
                                                <ListItemAvatar style={{ width: '24px' }}>
                                                    <Avatar
                                                        alt="Kubeconfig context selection"
                                                        src="/static/images/kubeimg.png"
                                                        style={{ height: '1.5rem', width: '1.5rem' }}
                                                    />
                                                </ListItemAvatar>
                                                <ListItemText primary={cluster.context} />
                                            </ListItem>)}
                                    </List>
                                </div>
                                :
                                <div className={styles.EmptyListContainer}>
                                    <Typography style={{ padding: '2rem' }} variant="subtitle2">
                                        Loading...
                                        <Spin style={{ paddingLeft: '1rem' }} size='small'></Spin>
                                    </Typography>
                                </div>}
                        </Row>
                    </Col>
                </Row>
            </Layout>
        </div>
    );
}

export default Kubeconfig;
