import { Layout, Row, Col } from 'antd';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ComputerIcon from '@material-ui/icons/Computer';
import EqualizerOutlinedIcon from '@material-ui/icons/EqualizerOutlined';
import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';
import CodeOutlinedIcon from '@material-ui/icons/CodeOutlined';
import Typography from '@material-ui/core/Typography';
import 'antd/dist/antd.css';
import { useEffect, useRef } from 'react';
import styles from "./VisualEdge.module.scss";

const { Content } = Layout;

const userLocation = { x: 300, y: 190 };
const glooLocation = { x: 300, y: 778 };
const logLocation = { x: 732, y: 778 };
const vectorLocation = { x: 1166, y: 778 };
const splunkLocation = { x: 1693, y: 778 };
const targetLocation = { x: 300, y: 1344 };

const rectHeight: number = 240;
const rectWidth: number = 340;

interface EdgeProps {
    gateway: any;
    onChangeViewType: any;
    apis: any[];
}

function VisualEdge(props: EdgeProps) {
    const canvasRef = useRef<any>(null);

    let _iteration: number = 0;
    setInterval(() => {
        _iteration = ++_iteration % 4;
    }, 250);

    function getImageDimensions(img: HTMLImageElement) {
        const ratio: number = img.naturalWidth / img.naturalHeight;
        let width: number;
        let height: number;
        if (ratio > rectWidth / rectHeight) {
            width = rectWidth - 20;
            height = width / ratio;
        } else {
            height = rectHeight - 20;
            width = height * ratio;
        }

        return { width, height };
    }

    const arrowColorsOrange = [
        "#e35500",
        "#e87700",
        "#ec9100",
        "#f0ab00",
    ];

    const arrowColorsGreen = [
        "#50b800",
        "#68c900",
        "#55da00",
        "#54e802",
    ];

    enum ArrowDirection {
        DOWN,
        UP,
        LEFT,
        RIGHT,
    }

    enum ColorTheme {
        ORANGE,
        GREEN,
    }

    useEffect(() => {
        let dpi = window.devicePixelRatio;

        // for smooth rendering of images
        function fixDpi() {
            let style = {
                height() {
                    return +getComputedStyle(canvas).getPropertyValue('height').slice(0, -2);
                },
                width() {
                    return +getComputedStyle(canvas).getPropertyValue('width').slice(0, -2);
                }
            }

            canvas.setAttribute('width', style.width() * dpi);
            canvas.setAttribute('height', style.height() * dpi);
        }

        function drawArrow(x: number, y: number, width: number, height: number, direction: ArrowDirection, colorTheme: ColorTheme = ColorTheme.ORANGE) {
            let blockHeight: number = height;
            let blockWidth: number = width;
            let actualIteration: number = _iteration;
            x = x + 4;
            switch (direction) {
                case ArrowDirection.DOWN:
                    actualIteration = 3 - _iteration;
                    blockHeight = height / 4;
                    break;
                case ArrowDirection.UP:
                    blockHeight = height / 4;
                    break;
                case ArrowDirection.LEFT:
                    blockWidth = width / 4;
                    break;
                case ArrowDirection.RIGHT:
                    actualIteration = 3 - _iteration;
                    blockWidth = width / 4;
                    break;
            }

            const startColorIndex = actualIteration;
            for (let i: number = 0; i < 4; i++) {
                let colorIndex: number = (startColorIndex + i) % 4;
                let path2D = new Path2D();
                let _x = x;
                let _y = y;

                switch (direction) {
                    case ArrowDirection.DOWN:
                    case ArrowDirection.UP:
                        _y = _y + i * blockHeight;
                        break;
                    case ArrowDirection.LEFT:
                    case ArrowDirection.RIGHT:
                        _x = _x + i * blockWidth;
                        break;
                }

                path2D.rect(_x, _y, blockWidth, blockHeight);
                if (colorTheme === ColorTheme.ORANGE) {
                    context.fillStyle = arrowColorsOrange[colorIndex];
                } else if (colorTheme === ColorTheme.GREEN) {
                    context.fillStyle = arrowColorsGreen[colorIndex];
                }
                context.fill(path2D);
            }

            // draw arrowhead
            const arrowHead = new Path2D();
            let point1X: number = 75;
            let point1Y: number = 50;
            let point2X: number = 100;
            let point2Y: number = 75;
            let point3X: number = 100;
            let point3Y: number = 25;

            switch (direction) {
                case ArrowDirection.DOWN:
                    point1X = x - 0.5 * width;
                    point1Y = y + height;
                    point2X = x + 1.5 * width;
                    point2Y = y + height;
                    point3X = x + width / 2;
                    point3Y = y + height + 35;
                    break;
                case ArrowDirection.UP:
                    point1X = x - 0.5 * width;
                    point1Y = y;
                    point2X = x + 1.5 * width;
                    point2Y = y;
                    point3X = x + width / 2;
                    point3Y = y - 35;
                    break;
                case ArrowDirection.LEFT:
                    point1X = x;
                    point1Y = y - 0.5 * height;
                    point2X = x;
                    point2Y = y + 1.5 * height;
                    point3X = x - 35;
                    point3Y = y + height / 2;
                    break;
                case ArrowDirection.RIGHT:
                    point1X = x + width;
                    point1Y = y - 0.5 * height;
                    point2X = x + width;
                    point2Y = y + 1.5 * height;
                    point3X = x + width + 35;
                    point3Y = y + height / 2;
                    break;
            }

            context.strokeStyle = "black";
            context.lineWidth = 4;
            arrowHead.moveTo(point1X, point1Y);
            arrowHead.lineTo(point2X, point2Y);
            arrowHead.lineTo(point3X, point3Y);
            arrowHead.closePath();
            context.stroke(arrowHead);
        }

        const canvas = canvasRef.current;

        const context = canvas?.getContext('2d');
        context.globalCompositeOperation = 'copy';
        const userImg = new Image();
        userImg.src = '/static/images/user.png';
        userImg.onload = ((e: Event) => {
            requestAnimationFrame(draw);
        });
        const glooImg = new Image();
        glooImg.src = '/static/images/gloo-edge.png';
        const logImg = new Image();
        logImg.src = '/static/images/log.png';
        const vectorImg = new Image();
        vectorImg.src = '/static/images/vector.png';
        const splunkImg = new Image();
        splunkImg.src = '/static/images/splunk logo.png';
        const targetImg = new Image();
        targetImg.src = '/static/images/target.png';
        const settingsImg = new Image();
        settingsImg.src = '/static/images/settings.png';

        function draw() {
            fixDpi();
            context.clearRect(0, 0, canvas.width, canvas.height);
            drawArrow(250, 340, 25, 280, ArrowDirection.DOWN); // user-to-gloo
            drawArrow(315, 377, 25, 280, ArrowDirection.UP, 1); // gloo-to-user
            drawArrow(468, 770, 150, 20, ArrowDirection.RIGHT, 1); // gloo-to-log
            drawArrow(803, 770, 150, 20, ArrowDirection.RIGHT, 1); // log-to-vector
            drawArrow(1331, 770, 150, 20, ArrowDirection.RIGHT, 1); // vector-to-splunk
            drawArrow(250, 900, 25, 280, ArrowDirection.DOWN); // gloo-to-target
            drawArrow(315, 937, 25, 280, ArrowDirection.UP, 1); // target-to-gloo

            context.beginPath();
            context.rect(glooLocation.x - rectWidth / 2, glooLocation.y - rectHeight / 2, rectWidth, rectHeight);
            context.stroke();

            context.beginPath();
            context.rect(vectorLocation.x - rectWidth / 2, vectorLocation.y - rectHeight / 2, rectWidth, rectHeight);
            context.stroke();

            context.beginPath();
            context.rect(splunkLocation.x - rectWidth / 2, splunkLocation.y - rectHeight / 2, rectWidth, rectHeight);
            context.stroke();

            if (userImg.complete) {
                context.drawImage(userImg, userLocation.x - userImg.width * 0.8 / 2, userLocation.y - userImg.height * 0.8 / 2, userImg.naturalWidth * 0.8, userImg.naturalHeight * 0.8);
            }

            if (glooImg.complete) {
                const dimensions: any = getImageDimensions(glooImg);
                context.drawImage(glooImg, glooLocation.x - dimensions.width / 2, glooLocation.y - dimensions.height / 2, dimensions.width, dimensions.height);
            }

            if (logImg.complete) {
                const dimensions: any = getImageDimensions(logImg);
                context.drawImage(logImg, logLocation.x - dimensions.width / 2, logLocation.y - dimensions.height / 2, dimensions.width, dimensions.height);
            }

            if (vectorImg.complete) {
                const dimensions: any = getImageDimensions(vectorImg);
                context.drawImage(vectorImg, vectorLocation.x - dimensions.width / 2, vectorLocation.y - dimensions.height / 2, dimensions.width, dimensions.height);
            }

            if (splunkImg.complete) {
                const dimensions: any = getImageDimensions(splunkImg);
                context.drawImage(splunkImg, splunkLocation.x - dimensions.width / 2, splunkLocation.y - dimensions.height / 2, dimensions.width, dimensions.height);
            }

            if (targetImg.complete) {
                context.drawImage(targetImg, targetLocation.x - targetImg.width * 0.35, targetLocation.y - targetImg.height * 0.7 / 2, targetImg.naturalWidth * 0.7, targetImg.naturalHeight * 0.7);
            }

            // TODO: add settings icons when settings are supported
            // if (settingsImg.complete) {
            //     context.drawImage(settingsImg, vectorLocation.x + + rectWidth / 2, vectorLocation.y + rectHeight / 2, settingsImg.naturalWidth / 6, settingsImg.naturalHeight / 6);
            //     context.drawImage(settingsImg, glooLocation.x + rectWidth / 2, glooLocation.y + rectHeight / 2, settingsImg.naturalWidth / 6, settingsImg.naturalHeight / 6);
            // };

            requestAnimationFrame(draw);

        }

        requestAnimationFrame(draw);
    }, [props.gateway]);

    return (
        <>
            <Layout className={styles.wrapper} style={{ width: '100%', height: '100%', overflowY: 'hidden', }}>
                <Row style={{ height: '100%' }}>
                    <Col span={3}>
                        <Content>

                            <Card className={styles.card} >
                                <CardContent
                                    style={{ textAlign: 'left' }}
                                >
                                    <div style={{ textAlign: 'center' }}>
                                        <ComputerIcon />
                                        <Typography variant="h5" component="div">
                                            Gateway
                                        </Typography>
                                        <Typography variant="subtitle1" component="div">
                                            {props.gateway.name}
                                        </Typography>
                                        {props.gateway.labels?.region &&
                                            <Typography gutterBottom variant="subtitle2" component="div">
                                                {`(${props.gateway.labels?.region})`}
                                            </Typography>
                                        }
                                    </div>
                                    {props.gateway.edge &&
                                        <Typography variant="subtitle2">
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexWrap: 'wrap',
                                            }}>
                                                <span style={{ paddingLeft: "4px" }}>{`Edge: ${props.gateway.edge.name}`}</span>
                                            </div>
                                        </Typography>
                                    }
                                    {props.gateway.edge?.charts &&
                                        <Typography variant="subtitle2">
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexWrap: 'wrap',
                                            }}>
                                                <EqualizerOutlinedIcon />
                                                <span style={{ paddingLeft: "4px" }}>{`${props.gateway.edge?.charts.length} Charts`}</span>
                                            </div>
                                        </Typography>
                                    }
                                    {props.gateway.edge?.filters &&
                                        <Typography variant="subtitle2">
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexWrap: 'wrap',
                                            }}>
                                                <FilterListOutlinedIcon />
                                                <span style={{ paddingLeft: "4px" }}>{`${props.gateway.edge?.filters.length} Filters`}</span>
                                            </div>
                                        </Typography>
                                    }
                                    {props.apis &&
                                        <Typography variant="subtitle2">
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                flexWrap: 'wrap',
                                            }}>
                                                <CodeOutlinedIcon />
                                                <span style={{ paddingLeft: "4px" }}>{`${props.apis.length} APIs`}</span>
                                            </div>
                                        </Typography>
                                    }

                                </CardContent>
                            </Card>
                        </Content>
                    </Col>
                    <Col span={21}>
                        <canvas id="edgeCanvas" ref={canvasRef} className={styles.edgeCanvas}>
                        </canvas>
                    </Col>
                </Row>
            </Layout>
        </>
    );
}

export default VisualEdge;
