import apis from './mock-data/apis.json'
import cd from './mock-data/customdomains.json'
import clusterdata from './mock-data/clusterdata.json'
import gateways from './mock-data/gateways.json'

import MockAdapter from "axios-mock-adapter";

let apisList = apis.apis;
let cdsList = cd.customdomains;

export const isMockEnabled = () => {
    return process.env.REACT_APP_MOCK_ENABLED === 'true'
};

export const initializeAxiosMockAdapter = (instance) => {
    const mock = new MockAdapter(instance);
    
    mock.onGet("/api").reply(() => getAPIs());
    mock.onGet(/\/api\/.+/).reply(config => getAPI(config));
    mock.onPost("/api").reply(config => addAPI(config));
    mock.onPut(/\/api\/.+/).reply(config => editAPI(config));
    mock.onDelete().reply(config => removeAPI(config));
    
    mock.onGet("/customDomain").reply(() => getCDs());
    mock.onGet(/\/customDomain\/.+/).reply(config => getCD(config));
    mock.onPost("/customDomain").reply(config => addCD(config));
    mock.onPut(/\/customDomain\/.+/).reply(config => editCD(config));
    mock.onDelete(/\/customDomain\/.+/).reply(config => removeCD(config));

    mock.onGet("/gateway").reply(() => getGateways());
};

export const getAPIs = () => {
    return [200, apisList]
};

export const getAPI = (config) => {
    const id = extractIdPathParamFromUrl(config);
    const api = apisList.find(c => c.id === id);
    return [200, api];
};

const extractIdPathParamFromUrl = (config) => {
    return config.url.split('/').pop();
};

export const addAPI = (config) => {
    const api = JSON.parse(config.data);
    apisList.push(api);
    return [200, api];
};

export const editAPI = (config) => {
    const id = extractIdPathParamFromUrl(config);
    const apiIndex = apisList.findIndex(c => c.id === id);
    const api = JSON.parse(config.data);
    apisList[apiIndex] = api;
    return [200, api];
};

export const removeAPI = (config) => {
    const id = extractIdPathParamFromUrl(config);
    const splittedID = id.split(".");
    const namespace = splittedID[0];
    const name = splittedID[1];
    apisList = apisList.filter(c => (c.name !== name && c.namespace !== namespace));
    return [204, null];
};

export const getCDs = () => {
    return [200, cdsList]
};

export const getCD = (config) => {
    const id = extractIdPathParamFromUrl(config);
    const cd = cdsList.find(c => c.id === id);
    return [200, cd];
};

export const addCD = (config) => {
    const cd = JSON.parse(config.data);
    cdsList.push(cd);
    return [200, cd];
};

export const editCD = (config) => {
    const id = extractIdPathParamFromUrl(config);
    const cdIndex = cdsList.findIndex(c => c.id === id);
    const cd = JSON.parse(config.data);
    cdsList[cdIndex] = cd;
    return [200, cd];
};

export const removeCD = (config) => {
    const id = extractIdPathParamFromUrl(config);
    cdsList = cdsList.filter(c => c.id !== id);
    return [204, null];
};

export const getGateways = () => {
    return [200, gateways]
};