import { Row, Col, Card } from 'antd';
import 'antd/dist/antd.css';
import Map from '../Map/Map';
import "./Overview.scss";
import { useState, useEffect } from 'react';
import { Chart, Doughnut } from 'react-chartjs-2';
import ChartDataLabels, { Context } from 'chartjs-plugin-datalabels';
import ComputerIcon from '@material-ui/icons/Computer';
import CodeOutlinedIcon from '@material-ui/icons/CodeOutlined';
import EqualizerOutlinedIcon from '@material-ui/icons/EqualizerOutlined';
import DomainIcon from "@material-ui/icons/Domain";
import { IGateway } from '../ResourceManagement/ResourceManagement';
import Center from "./Center"
import OverviewOrDetails from "../../components/OverviewOrDetails/OverviewOrDetails";


interface OverviewProps {
    data: any;
    gateways: IGateway[],
    showGateway: any,
}

let gatewayChartData = {
    labels: ['Ready', 'Not Ready'],
    datasets: [
        {
            data: [0, 0], //[ready count, not ready count]
            backgroundColor: [
                'rgba(79, 184, 28, 0.6)',
                'rgba(230, 53, 56, 0.6)',
            ],
            borderColor: [
                'rgba(79, 184, 28, 1)',
                'rgba(252, 56, 59, 1)',
            ],
            borderWidth: 0,
            datalabels: {
                formatter: (value: any, context: Context) => {
                    return chartDataLabelFormatter(value)
                },
            }
        },
    ],
};

let customDomainsChartData = {
    labels: ['Ready', 'Not Ready'],
    datasets: [
        {
            data: [0, 0], //[ready count, not ready count]
            backgroundColor: [
                'rgba(79, 184, 28, 0.6)',
                'rgba(252, 56, 59, 0.6)',
            ],
            borderColor: [
                'rgba(79, 184, 28, 1)',
                'rgba(252, 56, 59, 1)',
            ],
            borderWidth: 0,
            datalabels: {
                formatter: (value: any, context: Context) => {
                    return chartDataLabelFormatter(value)
                },
            }
        },
    ],
};

let apisChartData = {
    labels: ['Ready', 'Not Ready'],
    datasets: [
        {
            data: [0, 0], //[ready count, not ready count]
            backgroundColor: [
                'rgba(79, 184, 28, 0.6)',
                'rgba(252, 56, 59, 0.8)',
            ],
            borderColor: [
                'rgba(79, 184, 28, 1)',
                'rgba(252, 56, 59, 1)',
            ],
            borderWidth: 0,
            datalabels: {
                formatter: (value: any, context: Context) => {
                    return chartDataLabelFormatter(value)
                },
            }
        },
    ],
};

let chartsStatusData = {
    labels: ['Ready', 'Not Ready'],
    datasets: [
        {
            data: [0, 0], //[ready count, not ready count]
            backgroundColor: [
                'rgba(79, 184, 28, 0.6)',
                'rgba(252, 56, 59, 0.6)',
            ],
            borderColor: [
                'rgba(79, 184, 28, 1)',
                'rgba(252, 56, 59, 1)',
            ],
            borderWidth: 0,
            datalabels: {
                formatter: (value: any, context: Context) => {
                    return chartDataLabelFormatter(value)
                },
            }
        },
    ],
};

const templateData = {
    labels: ['Ready', 'Not Ready'],
    datasets: [
        {
            data: [0, 0], //[ready count, not ready count]
            backgroundColor: [
                'rgba(75, 192, 192, 0.2)',
                'rgba(252, 56, 59, 0.2)',
            ],
            borderColor: [
                'rgba(75, 192, 192, 1)',
                'rgba(252, 56, 59, 1)',
            ],
            borderWidth: 1,
        },
    ],
};

function chartDataLabelFormatter(value: number) {
    return value > 0 ? value : "";
}

function Overview(props: OverviewProps) {
    const [isLoading, setIsLoading] = useState(true);
    const [gatewaysChartData, setGatewaysChartData] = useState<any>(templateData);
    const [CDChartData, setCDChartData] = useState<any>(templateData);
    const [APIsChartData, setAPIsChartData] = useState<any>(templateData);
    const [ChartsChartData, setChartsChartData] = useState<any>(templateData);

    useEffect(() => {
        Chart.defaults.plugins.legend.display = false;
        Chart.register(ChartDataLabels);
        if (Chart.defaults.plugins.datalabels) {
            Chart.defaults.plugins.datalabels.color = 'black';
        }
        if (props.gateways?.length) {
            setIsLoading(false);
            calcChartData()
        } else {
            setIsLoading(true);
        }
    }, [props.gateways?.length]);

    function calcChartData() {
        apisChartData.datasets[0].data = [0, 0]
        for (const api of props.data.api) {
            api["published-status"] === "True" ? apisChartData.datasets[0].data[0]++ : apisChartData.datasets[0].data[1]++
        }
        setAPIsChartData(apisChartData)

        gatewayChartData.datasets[0].data = [0, 0]
        for (const gateway of props.data.gateway) {
            gateway.online ? gatewayChartData.datasets[0].data[0]++ : gatewayChartData.datasets[0].data[1]++
        }
        setGatewaysChartData(gatewayChartData)

        customDomainsChartData.datasets[0].data = [0, 0]
        for (const cd of props.data.customDomain) {
            cd["ready-status"] ? customDomainsChartData.datasets[0].data[0]++ : customDomainsChartData.datasets[0].data[1]++
        }
        setCDChartData(customDomainsChartData)

        chartsStatusData.datasets[0].data = [0, 0]
        if (props.data.center.charts) {
            for (const chart of props.data.center.charts) {
                chart["release-status"] ? chartsStatusData.datasets[0].data[0]++ : chartsStatusData.datasets[0].data[1]++
            }
        }
        setChartsChartData(chartsStatusData)
    }

    return (
        <OverviewOrDetails
            overview={
                <>
                    <Col className={"MapColumn"} offset={1} span={20}>
                        <Map data={props.data} gateways={props.gateways} showGateway={props.showGateway} />
                    </Col>
                    <Col className={"ChartsColumn"} span={24}>
                        <Row className={"FullHeight"} gutter={[16, 16]}>
                            <Col className={"FullHeight"} span={6}>
                                <Card className={"FullHeight"} loading={isLoading} title="Gateways"
                                    extra={<ComputerIcon />}>
                                    <Doughnut
                                        options={{
                                            maintainAspectRatio: false,
                                            responsive: true,
                                            cutout: "65%",
                                            spacing: 0,
                                        }}
                                        height="100%" data={gatewaysChartData} />
                                </Card>
                            </Col>
                            <Col className={"FullHeight"} span={6}>
                                <Card className={"FullHeight"} loading={isLoading} title="Custom Domains"
                                    extra={<DomainIcon />}>
                                    <Doughnut
                                        options={{ maintainAspectRatio: false, responsive: true, cutout: "65%" }}
                                        height="100%" data={CDChartData} />
                                </Card>
                            </Col>
                            <Col className={"FullHeight"} span={6}>
                                <Card className={"FullHeight"} loading={isLoading} title="APIs"
                                    extra={<CodeOutlinedIcon />}>
                                    <Doughnut
                                        options={{ maintainAspectRatio: false, responsive: true, cutout: "65%" }}
                                        height="100%" data={APIsChartData} />
                                </Card>
                            </Col>
                            <Col className={"FullHeight"} span={6}>
                                <Card className={"FullHeight"} loading={isLoading} title="Charts"
                                    extra={<EqualizerOutlinedIcon />}>
                                    <Doughnut
                                        options={{
                                            maintainAspectRatio: false,
                                            responsive: true,
                                            cutout: "65%",
                                            spacing: 0
                                        }}
                                        height="100%" data={ChartsChartData} />
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </>
            } details={
                <Center center={props.data.center} configmaps={props.data.configmaps} />
            }
        />
    );
}

export default Overview;