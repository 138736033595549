import { useEffect, useReducer } from 'react';
import { Select, Modal, Form, Input, Typography } from 'antd';
import { APIRecordFields } from '../ResourceTable/APIsResourceTable';
import { getColumnTitleByColName } from '../../utils';
import { useContext, useState } from 'react';
import CGAppContext from '../../AppContext';

const { Option } = Select;
const { Text } = Typography;

type Props = {
    open: boolean;
    handleClose: () => void;
    handleConfirm: (createFormData: any) => void;
};

const formReducer = (state: any, event: any) => {
    if (event.clear) {
        return {};
    }

    return {
        ...state,
        [event.name]: event.value
    }
}

export default function CreateAPIResourceDialog(props: Props) {
    const appContext = useContext(CGAppContext);
    const [formData, setFormData] = useReducer(formReducer, {});
    const [form] = Form.useForm();
    const [customDomainHelperText, setCustomDomainHelperText] = useState<string>("");

    const handleClose = () => {
        props.handleClose();
    }
    const handleConfirm = (event: any) => {
        form
            .validateFields()
            .then(values => {
                form.resetFields();
                event.preventDefault();
                props.handleConfirm(formData);
                props.handleClose();
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    }

    const handleChange = (event: any) => {
        const target = event.target;
        const name = target.name;
        let value = target.value;
        if (target.type === 'number') {
            value = parseInt(value);
        }
        setFormData({
            name,
            value,
        });
    }

    const handleNamespcaeChange = (value: string) => {
        setFormData({
            name: APIRecordFields.namespace,
            value,
        });

        if (value) {
            setCustomDomainHelperText(`Showing only custom domains for selected namespace '${value}'`);
        } else {
            setCustomDomainHelperText('');
        }

        const customDomainName: string = formData[APIRecordFields.sourceDomain] || '';
        const customDomain: any = appContext.customDomains?.find(customDomain => customDomain?.domain === customDomainName);
        if (customDomain && customDomain.namespace !== value) {
            setFormData({
                name: APIRecordFields.sourceDomain,
                value: '',
            });
            form.resetFields([APIRecordFields.sourceDomain]);
        }
    }

    const handleDomainChange = (value: string) => {
        form.setFieldsValue({[APIRecordFields.sourceDomain]: value});

        setFormData({
            name: APIRecordFields.sourceDomain,
            value,
        });

        const customDomain: any = appContext.customDomains?.find(customDomain => customDomain?.domain === value);
        if (customDomain) {
            form.setFieldsValue({
                [APIRecordFields.namespace]: customDomain.namespace,
            });

            setFormData({
                name: APIRecordFields.namespace,
                value: customDomain.namespace,
            });

            setCustomDomainHelperText(`Showing only custom domains for selected namespace '${customDomain.namespace}'`);
        }
    }

    const handleSelectChange = (values: any[], allSelectedOptions: any) => {
        // e.g.: "gateway-selectors": {"EU": true, "US": true}
        const gwSelectors: any = {};

        for (const value of values) {
            const selector: any = appContext.gatewayLabels?.get(value);
            gwSelectors[selector.key] = selector.value;
        }

        setFormData({
            name: APIRecordFields.gatewaySelectors,
            value: gwSelectors,
        });
    }

    const layout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 16,
        },
    };

    const validateMessages = {
        // eslint-disable-next-line no-template-curly-in-string
        required: '${label} is required.',
        types: {
            // eslint-disable-next-line no-template-curly-in-string
            email: '${label} is not a valid email.',
            // eslint-disable-next-line no-template-curly-in-string
            number: '${label} is not a valid number.',
        },
        number: {
            // eslint-disable-next-line no-template-curly-in-string
            range: '${label} must be between ${min} and ${max}',
        },
    };

    useEffect(() => {
        if (props.open) {
            form.resetFields();
            setFormData({ clear: true });

            form.setFieldsValue({
                [APIRecordFields.targetPort]: 443,
            });

            setFormData({
                name: APIRecordFields.targetPort,
                value: 443,
            });
        }

        if (appContext.cluster?.namespace) {
            form.setFieldsValue({
                [APIRecordFields.namespace]: appContext.cluster?.namespace,
            });

            setFormData({
                name: APIRecordFields.namespace,
                value: appContext.cluster?.namespace ? appContext.cluster?.namespace : '',
            });
        }

    }, [props.open]);

    function getNamespaces(): string[] {
        const response: string[] | undefined = appContext?.customDomains?.reduce((arr: any[], customDomain) => {
            if (!arr.includes(customDomain.namespace)) {
                arr.push(customDomain.namespace);
            }
            return arr;
        }, []);

        return response || [];
    }

    function getCustomDomains(): string[] {
        const currentNamespace = formData[APIRecordFields.namespace];

        let response: string[] | undefined;

        if (currentNamespace) {
            response = appContext?.customDomains?.reduce((arr, customDomain) => {
                if (customDomain.namespace === currentNamespace) {
                    arr.push(customDomain.domain);
                }
                return arr;
            }, []);
        } else {
            response = appContext?.customDomains?.map((customDomain) => customDomain.domain);
        }

        return response || [];
    }

    return (
        <Modal width={600} title={`Create API`} visible={props.open} okText={'Create'} onOk={handleConfirm} onCancel={handleClose}>
            <Form
                {...layout}
                name="nest-messages"
                form={form}
                validateMessages={validateMessages}
            >
                <Form.Item
                    name={APIRecordFields.name}
                    label={getColumnTitleByColName(APIRecordFields.name)}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input name={APIRecordFields.name} onChange={handleChange} />
                </Form.Item>
                <Form.Item
                    name={APIRecordFields.namespace}
                    label={getColumnTitleByColName(APIRecordFields.namespace)}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Select a namespace for this API"
                        onChange={handleNamespcaeChange}
                    >
                        {getNamespaces().map((namespace) => {
                            return <Option key={namespace} value={namespace}>{`${namespace}`}</Option>
                        }, [])}
                    </Select>
                </Form.Item>
                <Form.Item
                    name={APIRecordFields.sourcePrefix}
                    label={getColumnTitleByColName(APIRecordFields.sourcePrefix)}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input name={APIRecordFields.sourcePrefix} onChange={handleChange} />
                </Form.Item>
                <Form.Item
                    name={APIRecordFields.sourceDomain}
                    label="Custom Domain"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Select a custom domain for this API"
                        onChange={handleDomainChange}
                    >
                        {getCustomDomains().map((value: any) => {
                            return <Option key={value} value={value}>{`${value}`}</Option>
                        })}
                    </Select>
                    <Text className="ant-form-text" type="secondary" style={{ display: customDomainHelperText !== "" ? 'block' : 'none' }}>
                        {customDomainHelperText}
                    </Text>
                </Form.Item>
                <Form.Item
                    name={APIRecordFields.targetHost}
                    label={getColumnTitleByColName(APIRecordFields.targetHost)}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input name={APIRecordFields.targetHost} onChange={handleChange} />
                </Form.Item>
                <Form.Item
                    name={APIRecordFields.targetPort}
                    label={getColumnTitleByColName(APIRecordFields.targetPort)}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input
                        type='number'
                        name={APIRecordFields.targetPort}
                        onChange={handleChange}
                    />
                </Form.Item>
                <Form.Item name={APIRecordFields.gatewaySelectors}
                    label={getColumnTitleByColName(APIRecordFields.gatewaySelectors)}>
                    <Select
                        mode="multiple"
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Choose conditions that will bind gateways to this API"
                        onChange={handleSelectChange}
                    >
                        {appContext.gatewayLabels && Array.from(appContext.gatewayLabels.keys()).map((key: string) => {
                            return <Option key={key} value={key}>{`${appContext.gatewayLabels?.get(key).key} = ${appContext.gatewayLabels?.get(key).value}`}</Option>
                        })}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
}
