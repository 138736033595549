import React from 'react';

const CGAppContext = React.createContext<{
    kubeconfig: string,
    cluster: {context?: string, lob?: string, group?: string, namespace?: string},
    gatewayLabels?: Map<string, any>,
    customDomains?: any[],
}>({
    kubeconfig: "",
    cluster: {},
    gatewayLabels: new Map<string, any>(),
    customDomains: [],
});

export default CGAppContext;