import _ from "lodash";
import {
    Table,
    TableColumnType,
    TablePaginationConfig,
    Descriptions,
    Layout,
    Empty,
    Card,
    Typography,
    Divider,
    Input,
    Tooltip,
    Button,
} from 'antd';
import {
    CloseCircleOutlined,
    CheckCircleOutlined,
    InfoCircleOutlined,
    CopyOutlined
} from '@ant-design/icons';
import { TableRowSelection, FilterDropdownProps, FilterValue, SorterResult, TableCurrentDataSource, ColumnFilterItem } from 'antd/lib/table/interface';
import { renderList } from './columnRenderers/renderList';
import { renderLink } from './columnRenderers/renderLink';
import { renderStatus } from './columnRenderers/renderStatus';
import { renderPublishedStatus } from './columnRenderers/renderPublishedStatus';
import 'antd/dist/antd.css';
import './APIsResourceTable.scss';
import { getBasicColumnDefinition, getColumnNamesByRows, getRowsDataPerColumn } from "./ResourceTable"
import { APIS, getColumnTitleByColName, splitStringByCamelCase } from '../../utils';
import CustomFilterMenu from './CustomFilterMenu';
import Loading from "../../pages/Loading/Loading";
import { useEffect, useState } from "react";

const { Content } = Layout;
const { Meta } = Card;

export enum APIRecordFields {
    name = "name",
    namespace = "namespace",
    source = "source",
    sourcePrefix = "source-prefix",
    sourceDomain = "source-domain",
    targetHost = "target-host",
    targetPort = "target-port",
    routes = "routes",
    gatewaySelectors = "gateway-selectors",
    envSelectors = "environment-selectors",
    publishedStatus = "published-status",
    publishedReason = "published-reason",
    publishedMessage = "published-message",
    dnsStatus = "dns-status",
    dnsName = "dns-name",
    dnsProvider = "dns-provider",
    federatedStatus = "federated-status",
    federatedConditions = "federated-conditions",
    certStatus = "certificate-status",
    certIssuer = "certificate-issuer",
    gateways = "gateways",
    lob = "lob",
    desired = "desired",
    actual = "actual",
}

/**
 * Row Record fields names as they are recieved from server
 */
interface RowRecord {
    name: string;
    namespace: string;
    "source-prefix": string;
    "source-domain": string;
    "target-host": string;
    "target-port": number;
    routes: any[],
    "gateway-selectors": { [key: string]: string; };
    "environment-selectors": [],
    "published-status": string;
    "published-reason": string;
    "published-message": string;
    "dns-status": boolean;
    "dns-name": string;
    "dns-provider": string;
    "certificate-status": boolean;
    "certificate-issuer": string;
    gateways: Array<string>;
}

interface APIsResourceTableProps {
    tableState: any;
    dataSource: any;
    onChange: (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<any> | SorterResult<any>[], extra: TableCurrentDataSource<any>) => void;
    pagination: false | TablePaginationConfig;
    rowSelection: TableRowSelection<any>;
    rowKey: string;
    currentGateway?: string;
}

export default function APIsResourceTable(props: APIsResourceTableProps) {
    // Declare columns explicitly and not derive from the primary columns since there might be fields we don't want to display at all.
    // TODO remove publishedStatus? looks redundant to show both status (publishedStatus, publishedReason)
    const primaryColumnNames: string[] = [
        APIRecordFields.sourceDomain,
        APIRecordFields.source,
        APIRecordFields.targetHost,
        APIRecordFields.namespace,
        APIRecordFields.publishedStatus,
        APIRecordFields.publishedReason,
        APIRecordFields.gateways
    ];

    const detailedColumnNames: string[] = [
        APIRecordFields.name,
        APIRecordFields.namespace,
        APIRecordFields.sourcePrefix,
        APIRecordFields.gatewaySelectors,
        APIRecordFields.envSelectors,
        APIRecordFields.lob,
    ];

    const dnsColumnNames: string[] = [
        APIRecordFields.dnsStatus,
        APIRecordFields.dnsName,
        APIRecordFields.dnsProvider,
    ];

    const certificateColumnNames: string[] = [
        APIRecordFields.certStatus,
        APIRecordFields.certIssuer,
    ];

    const routesColumnNames: string[] = [
        APIRecordFields.routes,
    ];

    const statusColumnNames: string[] = [
        // APIRecordFields.federatedStatus,
        APIRecordFields.publishedStatus,
        APIRecordFields.publishedMessage,
        APIRecordFields.desired,
        APIRecordFields.actual,
    ];

    /**
     * Takes ALL columns and returns a subset that matches the will be shown in table
     */
    const getPrimaryColumns = (columns: TableColumnType<any>[]): TableColumnType<any>[] => {
        return columns?.filter((column) => primaryColumnNames.includes(column.key as string));
    }

    /**
     * Returns specific columns definitions according to list and column names
     * 
     * See code example for column options: https://github.com/mui-org/material-ui-x/blob/14a2b22b371579778bfd06539b8807dc0314b2e1/packages/grid/x-grid-data-generator/src/commodities.columns.tsx#L53
     * 
     * @param listName 
     * @param columnName 
     * @returns 
     */
    function getColumnDefinition(listName: string, columnName: string, rowsDataPerColumn: any, tableState: any) {
        // General column definition
        const columnDefinition: TableColumnType<any> = getBasicColumnDefinition(columnName, rowsDataPerColumn, tableState);

        if (columnName === APIRecordFields.source) {
            columnDefinition.ellipsis = false;
            columnDefinition.render = renderLink;
            columnDefinition.filterDropdown = (props: FilterDropdownProps) => <CustomFilterMenu listName={listName} columnName={columnName} filterDropdownProps={{ ...props }} />;
        }
        else if (columnName === APIRecordFields.sourceDomain) {
            columnDefinition.title = "Custom Domain";
            columnDefinition.ellipsis = false;
            columnDefinition.filterDropdown = (props: FilterDropdownProps) => <CustomFilterMenu listName={listName} columnName={columnName} filterDropdownProps={{ ...props }} />;
        }
        else if (columnName === APIRecordFields.gateways) {
            columnDefinition.render = renderList;
            columnDefinition.ellipsis = false;
            columnDefinition.filterDropdown = (props: FilterDropdownProps) => <CustomFilterMenu listName={listName} columnName={columnName} filterDropdownProps={{ ...props }} />;
        }
        // TODO remove? looks redundant to show both status (publishedStatus, publishedReason)
        else if (columnName === APIRecordFields.publishedStatus) {
            columnDefinition.render = renderStatus;
            columnDefinition.filters = _.map(rowsDataPerColumn && rowsDataPerColumn[columnName], (columnPossibleValue: string) => {
                return { text: columnPossibleValue?.toString() === "True" ? "Ready" : "Not Ready", value: columnPossibleValue?.toString() }
            }) || [] as ColumnFilterItem[];
        }
        else if (columnName === APIRecordFields.publishedReason) {
            columnDefinition.title = "Status";
            columnDefinition.render = renderPublishedStatus;
            columnDefinition.filters = _.map(rowsDataPerColumn && rowsDataPerColumn[columnName], (columnPossibleValue: string) => {
                return { text: splitStringByCamelCase(columnPossibleValue?.toString()), value: columnPossibleValue?.toString() }
            }) || [] as ColumnFilterItem[];
        }
        else if (columnName === APIRecordFields.lob) {
            columnDefinition.title = "LOB";
            columnDefinition.filterDropdown = (props: FilterDropdownProps) => <CustomFilterMenu listName={listName} columnName={columnName} filterDropdownProps={{ ...props }} />;
        }

        return columnDefinition;
    }

    function getColsAndRows(listName: string, listRows: RowRecord[], tableState: any) {
        let columns: TableColumnType<any>[] = [];
        let rows: any = [];

        if (!_.isEmpty(listRows)) {
            rows = _.map(listRows, (row: any) => {
                _.forEach(Object.keys(row), (columnName: string) => {
                    if (_.isPlainObject(row[columnName])) {
                        // Need to convert the object data
                        // TODO need to make sure what are the possible values and how to present them better
                        row[columnName] = _.map(row[columnName], (value, key) => `${key}=${JSON.stringify(value)}`).join(",");
                    }
                });
                return {
                    key: `${row.namespace}-${row.name}`,
                    source: `${row[APIRecordFields.sourcePrefix]}.${row[APIRecordFields.sourceDomain]}`,
                    ...row
                }
            }) || [];

            const rowsDataPerColumn = getRowsDataPerColumn(rows);
            const columnNames = getColumnNamesByRows(rows);
            columns = _.map(columnNames, (columnName) => {
                return getColumnDefinition(listName, columnName, rowsDataPerColumn, tableState);
            }) || [];
        }

        return { columns, rows };
    }

    const [data, setData] = useState<{ columns: TableColumnType<any>[], rows: any }>({ columns: [], rows: [] });
    const [primaryColumns, setPrimaryColumns] = useState<TableColumnType<any>[]>([]);

    useEffect(() => {
        const newColumnsAndRows = getColsAndRows(APIS, props.dataSource, props.tableState);
        setData(newColumnsAndRows);
        setPrimaryColumns(getPrimaryColumns(newColumnsAndRows.columns));
    }, [JSON.stringify(props.dataSource), props.tableState]);

    function DetailedInfo(props: any) {
        function getCurlCommand() {
            let url = props.data.source;
            if (!url.startsWith('https') && !url.startsWith('http')) {
                url = `https://${url}`;
            }
            return `curl -i -vk -H "Application-Interface-Key: xxxxxxxx" -X GET ${url}`;
        }

        function normalizeGatewayName(str: string): string {
            return (` ${str}`).toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g,
                (match, chr) => {
                    return chr.toUpperCase();
                });
        }

        function getFederatedAPIInfo(): Map<string, any> {
            // hacky parsing because graphql returns a mix of key=value, and json
            const map = new Map<string, any>();
            try {
                const str = props.data[APIRecordFields.federatedStatus];
                const regex = /(?:,?gateways(.+?)=)/g;
                const result = str.replace(/\\/g, '').split(regex);
                result.shift();
                for (let i = 0; i < result.length - 1; i += 2) {
                    map.set(result[i], JSON.parse(result[i + 1]));
                }
            } catch (e) {
                console.log(e);
            }

            return map;
        }

        function getFederatedAPIInfoForGateway(gateway: string): any {
            const map: Map<string, any> = getFederatedAPIInfo();
            const normalizedGatewayName: string = normalizeGatewayName(gateway);
            const entry = map.get(normalizedGatewayName);
            return entry;
        }

        function getRoutes(): any[] {
            const response = [];
            let routes: any = props.data.routes;
            if (routes) {
                for (const route of routes) {
                    const hosts = route.targetSingle?.upstream?.hosts;
                    if (hosts) {
                        for (const host of hosts) {
                            const address = host.address;
                            const port = host.port;
                            const label = route.targetSingle?.upstream?.name;
                            const value = `${address}:${port}`;
                            response.push({ label, value });
                        }
                    }
                    const weightedUpstreams = route.targetMulti?.weightedUpstreams;
                    if (weightedUpstreams) {
                        for (const weightedUpstream of weightedUpstreams) {
                            const weight = weightedUpstream.weight;
                            const hosts = weightedUpstream.upstream?.hosts;
                            if (hosts) {
                                for (const host of hosts) {
                                    const address = host.address;
                                    const port = host.port;
                                    const label = `${weightedUpstream.upstream.name} (${weight})`;
                                    const value = `${address}:${port}`;
                                    response.push({ label, value });
                                }
                            }
                        }
                    }
                }
            }

            return response;
        }

        function getDetailedStatus(gateway: string): any[] {
            const entry = getFederatedAPIInfoForGateway(gateway);
            let response: any[] = [];
            if (entry) {
                for (const key in entry) {
                    if (key !== 'ready') {
                        const value = entry[key];
                        if (key === 'stage') {
                            response.push({ key, state: value, ready: value === 'Placed' });
                        } else if (value) {
                            response.push({ key, state: value.state, ready: value.state === 'Ready' });
                        }
                    }
                }
            }
            return response;
        }

        return (
            <Layout>
                <Content>
                    <Card>
                        <Meta
                            avatar={<InfoCircleOutlined style={{ color: "black", fontSize: "150%" }} />}
                            title={<Typography.Title level={5}>Detailed information</Typography.Title>}
                        />
                        <Descriptions size="small" column={3}>
                            {
                                detailedColumnNames.map((columnName) => {
                                    const value: any = props.data[columnName];
                                    if (value) {
                                        return <Descriptions.Item
                                            label={getColumnTitleByColName(columnName)}
                                            className="description-item"
                                            key={columnName}
                                        >
                                            {value?.toString()}
                                        </Descriptions.Item>
                                    }
                                })
                            }
                        </Descriptions>
                        <Divider />
                        {props.currentGateway ?
                            <Typography.Title level={5}>Detailed Status</Typography.Title> :
                            <Typography.Title level={5}>Status overview</Typography.Title>
                        }
                        {!props.currentGateway && props.data[APIRecordFields.federatedConditions] && props.data[APIRecordFields.federatedConditions].map((condition: any) => {
                            return <Typography.Paragraph><Typography.Text
                                key={condition.reason}
                            >
                                {condition.status === 'True' ?
                                    <CheckCircleOutlined style={{ color: "green", marginRight: 4 }} /> :
                                    <CloseCircleOutlined style={{ color: "red", marginRight: 4 }} />}
                                {condition.message}
                            </Typography.Text></Typography.Paragraph>
                        })}
                        {props.currentGateway && getDetailedStatus(props.currentGateway).map((entry) => {
                            return <Typography.Paragraph><Typography.Text
                                key={entry.key}
                            >
                                {entry.ready ?
                                    <CheckCircleOutlined style={{ color: "green", marginRight: 4 }} /> :
                                    <CloseCircleOutlined style={{ color: "red", marginRight: 4 }} />}
                                {`${entry.key}: ${entry.state}`}
                            </Typography.Text></Typography.Paragraph>
                        })}
                        <Divider />
                        <Typography.Title level={5}>DNS</Typography.Title>
                        <Descriptions size="small" column={1}>
                            {
                                dnsColumnNames.map((columnName) => {
                                    let value: any = props.data[columnName];
                                    if (value) {
                                        if (props.currentGateway && columnName === APIRecordFields.dnsStatus) {
                                            const federatedAPIInfo: any = getFederatedAPIInfoForGateway(props.currentGateway);
                                            value = federatedAPIInfo.dns?.state;
                                        }
                                        return <Descriptions.Item
                                            label={getColumnTitleByColName(columnName)}
                                            className="description-item"
                                            key={columnName}
                                        >
                                            {value?.toString()}
                                        </Descriptions.Item>
                                    }
                                })
                            }
                        </Descriptions>
                        <Divider />
                        <Typography.Title level={5}>Certificates</Typography.Title>
                        <Descriptions size="small" column={1}>
                            {
                                certificateColumnNames.map((columnName) => {
                                    let value: any = props.data[columnName];
                                    if (value) {
                                        if (props.currentGateway && columnName === APIRecordFields.certStatus) {
                                            const federatedAPIInfo: any = getFederatedAPIInfoForGateway(props.currentGateway);
                                            value = federatedAPIInfo.certificate?.state;
                                        }
                                        return <Descriptions.Item
                                            label={getColumnTitleByColName(columnName)}
                                            className="description-item"
                                            key={columnName}
                                        >
                                            {value?.toString()}
                                        </Descriptions.Item>
                                    }
                                })
                            }
                        </Descriptions>
                        <Divider />
                        {props.data.routes &&
                            <Typography.Title level={5}>Routes</Typography.Title>}
                        {props.data.routes &&
                            <Descriptions size="small" column={1}>
                                {
                                    getRoutes().map((route) => {
                                        return <Descriptions.Item
                                            label={route.label}
                                            className="description-item"
                                            key={"routes"}
                                        >
                                            {route.value}
                                        </Descriptions.Item>
                                    })
                                }
                            </Descriptions>}
                        {props.data.routes &&
                            <Divider />}
                        <Typography.Title level={5}>Test API</Typography.Title>
                        <Input.Group compact>
                            <Input
                                style={{ width: 'calc(70% - 200px)' }}
                                disabled={true}
                                defaultValue={getCurlCommand()}
                            />
                            <Tooltip title="copy curl command">
                                <Button
                                    icon={<CopyOutlined />}
                                    onClick={() => {
                                        navigator.clipboard.writeText(getCurlCommand());
                                    }}
                                />
                            </Tooltip>
                        </Input.Group>
                    </Card>
                </Content>
            </Layout>
        );
    }

    return (<Table
        size={"middle"}
        sticky={true}
        scroll={{ x: "100%" }}
        columns={primaryColumns}
        dataSource={data.rows}
        onChange={props.onChange}
        pagination={props.pagination}
        rowSelection={props.rowSelection}
        expandable={{ expandedRowRender: record => <DetailedInfo data={record} currentGateway={props.currentGateway} /> }}
        rowKey={record => `${record.namespace}-${record.name}`}
        tableLayout={"auto"}
        locale={{
            emptyText: props.dataSource ? <Empty /> : <Loading />
        }}
    />);
}
