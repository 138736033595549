import { useState, useEffect } from 'react';
import CGAppContext from './AppContext';
import _ from "lodash";
import * as yaml from "js-yaml";
import { Switch, withRouter } from 'react-router-dom';
import AppliedRoute from './AppliedRoute';
import Home from './pages/Home';
import Signup from './pages/Login/SignUp';
import { setAuth } from './apolloClient';
import OnBoarding from "./pages/OnBoarding/OnBoarding";
import * as auth from './services/auth.service';

export interface HomeProps {
    isShowKubeconfigPage: any;
    isShowManagementPage: any;
    onAcceptedKubeconfig: any;
    onSetKubeCluster: (cluster: ({ context?: string, lob?: string, group?: string })) => void;
    kubeconfigContent: string;
    signOut: Function;
    switchContext: Function;
    isAuthenticated: boolean | null;
}

export interface AuthenticationProps {
    isAuthenticated: boolean | null;
    userHasAuthenticated: any;
}

function App() {
    const [isShowKubeconfigPage, setIsShowKubeconfigPage] = useState(true);
    const [isShowManagementPage, setIsShowManagementPage] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
    const [kubeconfigContent, setKubeconfigContent] = useState("");
    const [cluster, setCluster] = useState<{ context?: string, lob?: string, group?: string, namespace?: string }>({});

    useEffect(() => {
        auth.authenticate().then((isSuccess) => {
            if (isSuccess) {
                setIsAuthenticated(true);
            }
        });
    }, []);

    const showManagementPage = () => {
        setIsShowKubeconfigPage(false)
        setIsShowManagementPage(true)
    };

    const onSetKubeCluster = async (cluster: any) => {
        setCluster(cluster);
        await setAuth({ kubecontext: cluster.context });
        showManagementPage();
    };

    const onAcceptedKubeconfig = async (kubeconfig: any) => {
        let reader = new FileReader();
        reader.onload = async (e) => {
            if (e && e.target) {
                const res = e.target.result
                if (typeof res === "string") {
                    const content = yaml.load(res) as any
                    if (content["kind"] === "Config") {
                        //Todo check connectivity to cluster?
                        // what data on a cluster we can achieve?

                        const cluster: any = {};

                        setKubeconfigContent(btoa(res));
                        if (!_.isEmpty(content.contexts[0].context?.namespace)) {
                            cluster.namespace = content.contexts[0].context.namespace;
                        }
                        else {
                            console.log(`Could not fetch namespace from given kuneconfig. Using empty namespace instead.`);
                        }

                        if (!_.isEmpty(content.clusters[0].name)) {
                            cluster.context = content.clusters[0].name;
                            setCluster(cluster);
                        }
                        else {
                            console.log(`Could not fetch cluster name from given kuneconfig. Using empty cluster name instead.`);
                        }
                        await setAuth({ kubeconfig: btoa(res) });
                        showManagementPage();
                    }
                }
            }
        };
        reader.readAsText(kubeconfig[0]);
    };

    const signOut = async () => {
        setIsAuthenticated(false);
        setIsShowKubeconfigPage(false);
        await auth.signout();
        await auth.authenticate();
    }

    const switchContext = () => {
        setIsShowKubeconfigPage(true);
    }

    const homeProps: HomeProps = {
        isShowKubeconfigPage,
        isShowManagementPage,
        onAcceptedKubeconfig,
        onSetKubeCluster,
        kubeconfigContent,
        signOut,
        switchContext,
        isAuthenticated,
    };

    return (
        <>
            <CGAppContext.Provider value={{ kubeconfig: kubeconfigContent, cluster, gatewayLabels: new Map()}}>
                <Switch>
                    <AppliedRoute path="/" exact component={Home} props={homeProps} />
                    <AppliedRoute path="/signup" exact component={Signup} props={{}} />
                    <AppliedRoute path="/onBoarding" exact component={OnBoarding} props={{}} />
                </Switch>
            </CGAppContext.Provider>
        </>

    );
}

export default withRouter(App);
