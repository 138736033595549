import { Button } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import { apolloClient } from "../../../apolloClient";
import {
    gql,
} from "@apollo/client";

const CREATE_API = gql`
mutation CreateCoreApigwBtpSapComV1alpha1NamespacedAPI($namespace: String!, $comSapBtpApigwCoreV1alpha1APIInput: ComSapBtpApigwCoreV1alpha1APIInput!) {
    createCoreApigwBtpSapComV1alpha1NamespacedAPI(namespace: $namespace, comSapBtpApigwCoreV1alpha1APIInput: $comSapBtpApigwCoreV1alpha1APIInput) {
        metadata {
            name
            namespace
        }
        spec {
            source {
                domain
                hostPrefix
            }
            target {
                host
                port
            }
            gatewaySelector
        }
        status {
            conditions {
                message
                reason
                status
            }
        }
   }
}
`

export function doCreateAPI(createFormData: any) {
    const variables = {
        namespace: createFormData.namespace,
        comSapBtpApigwCoreV1alpha1APIInput: {
            apiVersion: "core.apigw.btp.sap.com/v1alpha1",
            kind: "API",
            metadata: {
                name: createFormData.name,
                namespace: createFormData.namespace,
            },
            spec: {
                target: {
                    host: createFormData['target-host'],
                    port: createFormData['target-port'],
                },
                source: {
                    domain: createFormData['source-domain'],
                    hostPrefix: createFormData['source-prefix'],
                },
                gatewaySelector: createFormData['gateway-selectors'],
            }
        }
    };

    try {
        apolloClient.mutate({
            mutation: CREATE_API,
            variables,
        });
    } catch(e) {
        console.error(e);
    }
}

export default function ToolbarCreateButton(props: any) {
    return (
        <>
            <Button
                color="primary"
                size="small"
                startIcon={<AddIcon />}
                data-modal="createAPI"
            > Create
            </Button>
        </>
    );
}
