import {Spin} from "antd";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  space: {
    padding : "1rem 1rem"
  }
}));

export default function Loading() {

  const classes = useStyles();

  return (
      <Spin className={classes.space} tip="Loading..."/>
  );
}