import { Button } from "@material-ui/core";
import SyncIcon from '@material-ui/icons/Sync';

interface Props {
    refreshTable: any
}

export default function ToolbarSyncButton(props: Props) {
    
    async function handleOnSync() {
        props.refreshTable();
    }

    return (<Button
        color="primary"
        size="small"
        startIcon={<SyncIcon />}
        onClick={handleOnSync}
    >Sync
    </Button>)
}