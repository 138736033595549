import {Button} from "@material-ui/core";
import {HomeProps} from "../App";
import Kubeconfig from '../pages/Kubeconfig/Kubeconfig'
import ResourceManagement from "../pages/ResourceManagement/ResourceManagement";
import {Layout,} from "antd";
import CentralGWTitleBar from "../components/CentralGWTitleBar";
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import {useHistory} from 'react-router-dom';
import {useState} from "react";
import CreateAPIResourceDialog from '../components/CreateResourceDialog/CreateAPIResourceDialog';
import {doCreateAPI} from '../components/ResourceTable/toolbar/ToolbarCreateButton';

export default function Home(props: HomeProps) {
    const history = useHistory();

    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [shouldPoll, setShouldPoll] = useState<boolean>(false);
    const [newApiKeys, setNewApiKeys] = useState<any>({});

    const openModal = (event: any) => {
        event.preventDefault()

        let {target: {dataset: {modal}}} = event;
        if (modal === undefined) {
            modal = event.target.parentElement.dataset.modal;
        }
        if (modal === 'createAPI') {
            setModalOpen(true);
        }
    }

    return (
        <Layout className="HomeLayout FullHeight" style={{minHeight: '100%'}} onClick={openModal}>
            {!props.isAuthenticated && <p>Loading Unified Gateway Admin ......................</p>}

            {props.isAuthenticated &&
            <CentralGWTitleBar signOut={props.signOut} switchContext={props.switchContext}
                               toolbarButtons={props.isShowKubeconfigPage ? [] : [
                                   <Button key={"onboarding"} startIcon={<FlightTakeoffIcon/>}
                                           onClick={() => history.push("/onBoarding")}
                                           style={{color: "white", marginLeft: "auto"}}>
                                       On Boarding
                                   </Button>
                               ]}/>
            }

            {props.isAuthenticated &&
            props.isShowKubeconfigPage ?
                <Kubeconfig onAcceptedKubeconfig={props.onAcceptedKubeconfig} onSetKubeCluster={props.onSetKubeCluster}
                            isAuthenticated={props.isAuthenticated}/> :
                props.isShowManagementPage ? <ResourceManagement shouldPoll={shouldPoll} newApiKeys={newApiKeys}/> :
                    <div/>
            }
            {// TODO: Use reducer on app context instead of props (https://reactjs.org/docs/hooks-faq.html#how-to-avoid-passing-callbacks-down)
                // Modal dialog is place high in the component hierarchy to avoid multiple renders of the dialog. See:
                // * https://dev.to/zettadam/how-to-handle-multiple-modals-in-a-react-application-2pei
                // * https://github.com/maxs15/react-native-modalbox/issues/181#issuecomment-354308094
            }
            <CreateAPIResourceDialog
                open={modalOpen}
                handleClose={() => {
                    setModalOpen(false)
                }}
                handleConfirm={(formData) => {
                    // this triggers the ResourceManagment to start polling
                    setNewApiKeys({name: formData.name, namespace: formData.namespace});
                    setShouldPoll(true);
                    // then reset the state
                    setTimeout(() => {
                        setShouldPoll(false)
                    }, 500);
                    doCreateAPI(formData);
                }}
            />
        </Layout>)
}
