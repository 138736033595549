import * as React from 'react';
import { splitStringByCamelCase } from '../../../utils';

import { Tag } from 'antd';
import {
    CheckCircleOutlined,
    SyncOutlined,
    CloseCircleOutlined,
    MinusCircleOutlined,
} from '@ant-design/icons';

declare type StatusMessage = 'PublishedSuccessfully' | 'PublishInProgress' | 'PublishFailed' | 'DeletionInProgress' | 'DeletionFailed' | 'Unknown';

interface StatusProps {
  statusMessage: StatusMessage;
}

const MessageStatus = React.memo((props: StatusProps) => {
  let { statusMessage } = props;
  let icon: any = null;
  let color: string = "";
  let readableStatusMessage = splitStringByCamelCase(statusMessage);
  
  if (statusMessage === 'PublishedSuccessfully') {
    icon = <CheckCircleOutlined />;
    color = "success"
  }
  else if (statusMessage === 'PublishInProgress'){
      icon = <SyncOutlined spin />;
      color = "processing"
  }
  else if ( statusMessage === 'PublishFailed' || statusMessage === 'DeletionFailed') {
    icon = <CloseCircleOutlined />;
    color = "error"
  }
  else if ( statusMessage === 'DeletionInProgress') {
      icon = <SyncOutlined spin />;
      color = "default"
  }
  else if ( statusMessage === 'Unknown') {
      icon = <MinusCircleOutlined />;
      color = "default"
  }
  
  return (
      <Tag icon={icon} color={color}>
          {readableStatusMessage}
      </Tag>
  );
});

export function renderPublishedStatus(value: any, record: any, index: number) {
  return (
    <MessageStatus statusMessage={value as StatusMessage} />
  ); 
}