import { Button } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import _ from "lodash";
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { apolloClient } from "../../../apolloClient";
import {
    gql,
    useMutation,
} from "@apollo/client";

interface Props {
    selectedRow: any,
    currentView: any,
    refreshTable: any,
    pollUntilDeleted: any,
}

export default function ToolbarDeleteButton(props: Props) {

    const DELETE_API = gql`
    mutation DeleteCoreApigwBtpSapComV1alpha1NamespacedAPI($namespace: String!, $name: String!) {
        deleteCoreApigwBtpSapComV1alpha1NamespacedAPI(namespace: $namespace, name: $name) {
            # status
            reason
            message
        }
    }
    `

    const [deleteCoreApigwBtpSapComV1alpha1NamespacedAPI] = useMutation(DELETE_API, {
        client: apolloClient,
    });

    async function handleOnDelete() {
        const { namespace, name } = props.selectedRow;

        const variables = {
            namespace,
            name,
        };

        await deleteCoreApigwBtpSapComV1alpha1NamespacedAPI({ variables });
        props.pollUntilDeleted(variables);
    }

    function confirm() {
        Modal.confirm({
            title: 'Confirm Deletion',
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure you want to delete?',
            okText: 'Delete',
            cancelText: 'Cancel',
            onOk: handleOnDelete
        });
    }

    return (
        <Button
            disabled={_.isEmpty(props.selectedRow)}
            color="primary"
            size="small"
            startIcon={<DeleteIcon />}
            onClick={confirm}
            data-modal="deleteAPI"
        >Delete
        </Button>
    )
}