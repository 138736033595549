import React, {useState} from 'react';
import styles from './OverviewOrDetails.module.scss';
import {Button, Col, Row,} from "antd";
import {SwapOutlined} from "@ant-design/icons"
import FlipCameraAndroidOutlinedIcon from "@material-ui/icons/FlipCameraAndroidOutlined";

interface OverviewDetailsProps {
    overview: any,
    details: any,
}

const detailsBtnText = "Show Details"
const overviewBtnText = "Show Overview"

function OverviewOrDetails(props: OverviewDetailsProps) {
    const [showDetails, setShowDetails] = useState(false);
    const [toggleViewBtnText, setToggleViewBtnText] = useState(detailsBtnText);

    function toggleDetailsView() {
        setShowDetails(!showDetails)
        // opposite because the `showDetails` param is not updated in this call
        !showDetails ? setToggleViewBtnText(overviewBtnText) : setToggleViewBtnText(detailsBtnText)
    }

    return (
        <Row className={`${styles.OverviewOrDetails.toString()} FullHeight`} justify="center">
            <Col className={styles.ActionColumn} span={24}>
                <Button
                    type="primary"
                    ghost
                    icon= {<SwapOutlined/>}
                    onClick={() => toggleDetailsView()}
                >
                    {toggleViewBtnText}
                </Button>

            </Col>
            <Col className={styles.MainColumn} span={24} >
                {showDetails ? props.details : props.overview}
            </Col>
        </Row>
    )
};

export default OverviewOrDetails;
