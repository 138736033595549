const config = {
    api: {
        region: 'eu-central-1',
        invokeUrl: 'https://api.admin.btpapigw.com',
    },
}

if (process?.env?.NODE_ENV === 'development') {
    config.api.invokeUrl = 'https://apidev.admin.btpapigw.com';
}

export default config;
