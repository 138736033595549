import _ from "lodash";
import { useState, useEffect } from 'react';
import { APIS, CUSTOM_DOMAINS, GATEWAYS, getColumnTitleByColName, CHARTS } from '../../utils';
import ToolbarCreateButton from './toolbar/ToolbarCreateButton';
import ToolbarDeleteButton from './toolbar/ToolbarDeleteButton';
import ToolbarSyncButton from './toolbar/ToolbarSyncButton';
import ToolbarGetAPIsOnGW from './toolbar/ToolbarGetAPIsOnGW';
import APIsResourceTable from "./APIsResourceTable";
import Loading from "../../pages/Loading/Loading";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { Color } from '@material-ui/lab/Alert';
// Ant Design imports
import { Table, Space, TableColumnType, TablePaginationConfig, Empty } from 'antd';
import { ColumnFilterItem, FilterDropdownProps, TableRowSelection } from 'antd/lib/table/interface';
import 'antd/dist/antd.css';
import CustomFilterMenu from "./CustomFilterMenu";
import { renderStatus } from "./columnRenderers/renderStatus";
import React from "react";

export enum CustomDomainRecordFields {
    name = "name",
    namespace = "namespace",
    domain = "domain",
    "cert-issuer-status" = "cert-issuer-status",
    "dns-provider-status" = "dns-provider-status",
    "ready-status" = "ready-status"
}

export enum GatewayRecordFields {
    name = "name",
    namespace = "namespace",
    labels = "labels",
    class = "class",
    online = "online"
}

export enum ChartRecordFields {
    stackName = "stack-name",
    namespace = "namespace",
    name = "name",
    version = "version",
    releaseStatus = "release-status",
    releaseStatusDescription = "release-status-description",
}

// ************************** General Utility functions **************************//                    
export function getBasicColumnDefinition(columnName: string, rowsDataPerColumn: any, tableState: any): TableColumnType<any> {
    const columnDefinition: TableColumnType<any> = {
        title: getColumnTitleByColName(columnName),
        dataIndex: columnName,
        key: columnName,
        width: 150,
        align: 'left',

        // Filter props
        filters: _.map(rowsDataPerColumn && rowsDataPerColumn[columnName], (columnPossibleValue: string) => {
            return { text: columnPossibleValue?.toString(), value: columnPossibleValue?.toString() }
        }) || [] as ColumnFilterItem[],
        onFilter: (value, record) => {
            // In case filtering is done with multiple values/tags
            if (_.isArray(value) && !_.isEmpty(value)) {

                // Column itself is defined as an array with multiple values
                if (_.isArray(record[columnName])) {
                    return _.some(record[columnName], (recValue) => _.some(value, (val) => recValue.indexOf(val) !== -1));
                }
                // Column itself has a string value
                return _.some(value, (opt: string) => record[columnName].indexOf(opt) !== -1);
            } else if (_.isArray(record[columnName])) {
                return record[columnName]?.includes(value);
            } else {
                return record[columnName]?.toString().includes(value.toString());
            }
        },
        filteredValue: (tableState.filteredInfo && tableState.filteredInfo[columnName]) || null,

        // Adding default renderCell for all cells for alignment.
        render: (value: any, record: any, index: number) => (
            <>{value ? value.toString() : value}</>
        ),
        ellipsis: true,

        // Sort props
        sorter: (a, b) => a[columnName] && b[columnName] && a[columnName].localeCompare && a[columnName].localeCompare(b[columnName]),
        sortOrder: tableState.sortedInfo && tableState.sortedInfo.columnKey === columnName && tableState.sortedInfo.order
    };
    return columnDefinition;
}

/**
 * Returns specific columns definitions according to list and column names
 *
 * See code example for column options: https://github.com/mui-org/material-ui-x/blob/14a2b22b371579778bfd06539b8807dc0314b2e1/packages/grid/x-grid-data-generator/src/commodities.columns.tsx#L53
 *
 * @param listName
 * @param columnName
 * @returns
 */
export function getColumnDefinition(listName: string, columnName: string, rowsDataPerColumn: any, tableState: any) {

    const columnDefinition: TableColumnType<any> = getBasicColumnDefinition(columnName, rowsDataPerColumn, tableState);

    if (listName === CUSTOM_DOMAINS) {
        switch (columnName) {
            case CustomDomainRecordFields.name:
                columnDefinition.width = 300;
                break;
            case CustomDomainRecordFields.namespace:
                columnDefinition.width = 150;
                columnDefinition.title = "Namespace";
                columnDefinition.filterDropdown = (props: FilterDropdownProps) => <CustomFilterMenu listName={listName}
                    columnName={columnName}
                    filterDropdownProps={{ ...props }} />;
                break;
            case CustomDomainRecordFields.domain:
                columnDefinition.width = 200;
                break;
            case CustomDomainRecordFields["cert-issuer-status"]:
                columnDefinition.render = renderStatus;
                break;
            case CustomDomainRecordFields["dns-provider-status"]:
                columnDefinition.render = renderStatus;
                break;
            case CustomDomainRecordFields["ready-status"]:
                columnDefinition.render = renderStatus;
                break;
            default:
                break;
        }
    }
    else if (listName === GATEWAYS) {
        switch (columnName) {
            case GatewayRecordFields.name:
                columnDefinition.width = 300;
                break;
            case GatewayRecordFields.namespace:
                columnDefinition.width = 150;
                break;
            case GatewayRecordFields.labels:
                break;
            case GatewayRecordFields.class:
                break;
            case GatewayRecordFields.online:
                columnDefinition.render = renderStatus
        }
    }
    else if (listName === CHARTS) {
        switch (columnName) {
            case ChartRecordFields.stackName:
                break;
            case ChartRecordFields.name:
                break;
            case ChartRecordFields.namespace:
                break;
            case ChartRecordFields.releaseStatus:
                break;
            case ChartRecordFields.releaseStatusDescription:
                break;
        }
    }

    return columnDefinition;
}

/**
 * Gets simple rows json object e.g. [{"name": "name1", "namespace": "namespace1", "gateways": ["gw0", "gw1"]}, {"name": "name2", "namespace": "namespace2", "gateways": ["gw2"]}] and returns
 *
 * The following data structure:
 * {"name": ["name1", "name2"], "namespace": ["namespace1", "namespace2"], "gateways": ["gw0", "gw1", "gw2"]}
 *
 * @param listRows the json object retrieved from the server
 * @returns
 */
export function getRowsDataPerColumn(listRows: any[]) {
    const rowsDataPerColumn: any = {};
    _.forEach(listRows, (row) => {
        _.forEach(row, (columnValue: string | Array<string>, columnName: string) => {

            // First make sure this entry is initialized
            if (_.isEmpty(rowsDataPerColumn[columnName])) {
                rowsDataPerColumn[columnName] = [];
            }

            if (_.isString(columnValue) && !_.includes(rowsDataPerColumn[columnName], columnValue)) {
                rowsDataPerColumn[columnName].push(columnValue);
            } else { // is Array
                rowsDataPerColumn[columnName] = _.uniq(_.concat(rowsDataPerColumn[columnName], columnValue))
            }

        });
    });
    return rowsDataPerColumn;
}

/**
 * Returns array of all column names based on the rows data.
 * Cannot count only on first/one row since server omits empty columns.
 *
 * @param listRows
 * @returns
 */
export function getColumnNamesByRows(listRows: any[]): string[] {
    let columnNames: any[] = [];

    listRows.forEach((row: any) => {
        const keys = Object.keys(row)
        keys.forEach((columnName: string) => {
            if (!columnNames.includes(columnName) && columnName !== "key") {
                columnNames.push(columnName);
            }
        });
    });
    return columnNames;
}

export function getColsAndRows(listName: string, listRows: any[], tableState: any) {
    let columns: TableColumnType<any>[] = [];
    let rows: any = [];

    if (!_.isEmpty(listRows)) {
        rows = _.map(listRows, (row) => {

            _.forEach(Object.keys(row), (columnName: string) => {
                if (_.isPlainObject(row[columnName])) {
                    // Need to convert the object data
                    // TODO need to make sure what are the possible values and how to present them better
                    row[columnName] = _.map(row[columnName], (value, key) => `${key}=${JSON.stringify(value)}`).join();
                }
            });

            return {
                key: `${row.namespace}.${row.name}`,
                ...row
            }
        }) || [];

        const rowsDataPerColumn = getRowsDataPerColumn(rows);

        const columnNames = getColumnNamesByRows(rows);
        columns = _.map(columnNames, (columnName) => {
            return getColumnDefinition(listName, columnName, rowsDataPerColumn, tableState);
        }) || [];

    }

    return { columns, rows };
}

// ************************** General Utility functions **************************//     

type Props = {
    list: any[];
    listName: string,
    currentView: any,
    handleResourceListChange: any,
    tableState: any,
    tableSelection: any,
    onRowSelectionChange: any,
    onTableChange: any,
    refreshTable: any,
    readonly?: boolean,
    currentGateway?: string,
}

let apis: any[] = [];

type MessageProps = {
    title: string,
    severity: Color,
};

export default function ResourceTable(props: Props) {
    useEffect(() => {
        apis = props.list;
    },
        [JSON.stringify(props.list)]
    );

    let data: { columns: TableColumnType<any>[], rows: any } = { columns: [], rows: [] };
    if (props.listName !== APIS) {
        data = getColsAndRows(props.listName, props.list, props.tableState);
    }

    // Pagination state. Can be common to all resource tables once changed in one of them.
    const DEFAULT_PAGE_SIZE = 15;
    const [paginationState, setPaginationState] = useState({
        pageSize: DEFAULT_PAGE_SIZE,
        current: 1
    });

    const [open, setOpen] = React.useState(false);
    const [messageProps, setMessageProps] = React.useState<MessageProps>({
        title: '',
        severity: 'success',
    });

    function pollUntilDeleted(variables: any) {
        const startTime: number = new Date().getTime();

        const handle = setInterval(() => {
            const currentTime: number = new Date().getTime();

            const foundAPI = apis.find((value) => {
                return value.namespace === variables.namespace && value.name === variables.name;
            });

            if (!foundAPI) {
                showMessage({
                    title: `API ${variables.name} in namespace ${variables.namespace} was deleted successfully`,
                    severity: 'success'
                });
                console.info(`API ${variables.name} in namespace ${variables.namespace} was deleted successfully`);
                clearInterval(handle);
            } else if (currentTime - startTime > 60000) {
                // Timeout: API wasn't delete
                showMessage({
                    title: `Timeout: API ${variables.name} in namespace ${variables.namespace} could not be deleted`,
                    severity: 'error'
                });
                console.error(`Timeout: API ${variables.name} in namespace ${variables.namespace} could not be deleted`);
                clearInterval(handle);
            }

            props.refreshTable();
        }, 1000);
    }

    function TableToolbar() {
        const selectedRow = props.tableSelection.selectedRows && props.tableSelection.selectedRows[0];
        return (
            <Space style={{ marginTop: 16, marginBottom: 16 }}>
                {/* CREATE should be only on API currently */}
                {props.listName === APIS && props.readonly !== true ?
                    <ToolbarCreateButton /> :
                    <div></div>}

                {/* EDIT won't be possible at first stage */}
                {/* <ToolbarEditButton currentView={props.currentView} selectedRow={selectedRow} /> */}

                {/* DELETE should be possible only on API */}
                {props.listName === APIS && props.readonly !== true ?
                    <ToolbarDeleteButton
                        selectedRow={selectedRow}
                        currentView={props.currentView}
                        refreshTable={props.refreshTable}
                        pollUntilDeleted={pollUntilDeleted}
                    /> : <div></div>}
                {props.listName === APIS && props.readonly !== true ? <ToolbarSyncButton refreshTable={props.refreshTable} /> : <div></div>}

                {props.listName === GATEWAYS ? <ToolbarGetAPIsOnGW selectedRow={selectedRow}
                    handleResourceListChange={props.handleResourceListChange} /> :
                    <div></div>}
            </Space>);
    }

    const paginationConfig: TablePaginationConfig = {
        position: ['bottomRight'],
        current: paginationState.current,
        pageSize: paginationState.pageSize,
        pageSizeOptions: ["5", "10", "20", "50", "100", "200"],
        showSizeChanger: false, // TODO: There is a bug when table is too big. Decide not to show it in case we'll show big tables in the demo.

        // Called when the page number or pageSize is changed, and it takes the resulting page number and pageSize as its arguments
        onChange: (current: number, pageSize?: number) => {
            setPaginationState({
                current,
                pageSize: pageSize || DEFAULT_PAGE_SIZE // pageSize is defined as optional but seems it is always sent. Must configure some fallback in case it is not defined -
                // in this case the fallback is 15.
            });
        }
    }

    const rowSelectionConfig: TableRowSelection<any> = {
        type: 'radio',
        selectedRowKeys: props.tableSelection.selectedRowKeys,
        onChange: props.onRowSelectionChange
    }

    const closeMessage = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    function showMessage(messageProps: MessageProps) {
        setMessageProps(messageProps);
        setOpen(true);
    }

    return (
        <div style={{ height: 500, width: '100%' }}>
            <TableToolbar />
            {props.listName === APIS ?
                <APIsResourceTable tableState={props.tableState}
                    dataSource={props.list}
                    onChange={props.onTableChange}
                    pagination={paginationConfig}
                    rowSelection={rowSelectionConfig}
                    currentGateway={props.currentGateway}
                    rowKey="key"
                />
                :
                <Table
                    size={"small"}
                    sticky={true}
                    columns={data.columns}
                    dataSource={data.rows ? data.rows : []}
                    onChange={props.onTableChange}
                    pagination={paginationConfig}
                    rowSelection={rowSelectionConfig}
                    rowKey="key"
                    locale={{
                        emptyText: data.rows.length !== 0 ? <Empty /> : <Loading />
                    }}
                />

            }
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={open}
                autoHideDuration={messageProps.severity === 'error' ? 12000 : 6000}
                onClose={closeMessage}
            >
                <MuiAlert onClose={closeMessage} severity={messageProps.severity}>
                    {messageProps.title}
                </MuiAlert>
            </Snackbar>
        </div >
    );
}