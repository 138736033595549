import { useReducer, useState, useContext } from 'react';
import { Button, TextField } from '@material-ui/core';
import { onBoarding } from "../../services/general-http.service";
import _ from "lodash";
import CentralGWTitleBar from '../../components/CentralGWTitleBar';
import { Card, Layout } from 'antd';
import { makeStyles } from "@material-ui/core/styles";
import FlightTakeoffIcon from "@material-ui/icons/FlightTakeoff";
import Typography from "@material-ui/core/Typography";
import AppContext from "../../AppContext";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Link } from 'react-router-dom';

const { Content } = Layout;

const useStyles = makeStyles((theme) => ({
    img: {
        width: "8rem",
        marginBottom: "1.5rem",
    }
}));

const formReducer = (state: any, event: any) => {
    return {
        ...state,
        [event.name]: event.value
    }
}

function OnBoarding(props: any) {
    const context = useContext(AppContext);
    const k8sContext = context.cluster.context;

    const [formData, setFormData] = useReducer(formReducer, {});
    const [isOnBoardSuccess, setOnBoardSuccess] = useState(true);
    const [isValidDomain, setIsValidDomain] = useState<boolean>(true);
    const [isValidEmail, setIsValidEmail] = useState<boolean>(true);
    const [wasSubmittedSuccessfully, setWasSubmittedSuccessfully] = useState<boolean>(false);
    const [newNamespace, setNewNamespace] = useState<string>("");
    const [shouldMessageAppear, setShouldMessageAppear] = useState(false);
    const [message, setMessage] = useState<string>('');

    const submitOnBoarding = async (formData: any) => {
        const body = {
            email: formData.email,
            subdomain: formData.subdomain,
            lob: formData.lob,
            context: k8sContext,
        };
        let onBoardResult;
        try {
            setWasSubmittedSuccessfully(true);
            onBoardResult = await onBoarding(body);
            if (onBoardResult.status >= 200 && onBoardResult.status < 300) {
                setNewNamespace(onBoardResult.data.namespace);
                setOnBoardSuccess(true);
                setShouldMessageAppear(true);
            } else {
                setWasSubmittedSuccessfully(false);
                setOnBoardSuccess(false);
                setShouldMessageAppear(true);
                setMessage('');
            }
        } catch (error: any) {
            setWasSubmittedSuccessfully(false);
            setMessage(error.response?.data?.message);
            setOnBoardSuccess(false);
            setShouldMessageAppear(true);
        }

    }

    const handleSubmit = async (event: any) => {
        event.preventDefault(); // prevents refreshing the page
        await submitOnBoarding(formData);
    }

    const handleChange = (event: any) => {
        if (event.target.name === 'subdomain') {
            setIsValidDomain(event.target.value.search(/^[a-z0-9]([-a-z0-9]*[a-z0-9])?(\.[a-z0-9]([-a-z0-9]*[a-z0-9])?)*$/) === 0);
        } else if (event.target.name === 'email') {
            setIsValidEmail(event.target.value.search(/^.*@.*$/) === 0);
        }

        setFormData({
            name: event.target.name,
            value: event.target.value,
        });
    }

    const isDisabled = () => {
        return _.isEmpty(formData.email) ||
            _.isEmpty(formData.subdomain) ||
            _.isEmpty(formData.lob) ||
            !isValidDomain ||
            !isValidEmail || wasSubmittedSuccessfully;
    }

    const closeMessage = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setShouldMessageAppear(false);
    };

    const classes = useStyles();

    return (
        <Layout style={{ minHeight: "100vh" }}>
            <CentralGWTitleBar signOut={props.signOut} roles={props.roles} />
            <Layout>
                <Content>
                    <div style={{ display: "flex", marginTop: "1rem", justifyContent: "center" }}>
                        <Card style={{ textAlign: "center", width: "450px" }}>

                            <img className={classes.img} alt="ccs logo" src="/static/images/ccslogo.png" />
                            <Typography component="h1" variant="h5">
                                Onboarding to Unified Gateway
                            </Typography>
                            <Typography >
                                Current context is {k8sContext}
                            </Typography>
                            <div>

                                <form onSubmit={handleSubmit} noValidate autoComplete="off">
                                    <TextField id="email-input"
                                        fullWidth
                                        label="Email address"
                                        name="email"
                                        required={true}
                                        onChange={handleChange}
                                        autoComplete="email"
                                        autoFocus
                                        margin="normal"
                                        type="email"
                                        variant="outlined"
                                        error={!isValidEmail}
                                        helperText={isValidEmail ? "" : "email must contain @"}
                                    />
                                    <TextField id="lob-input"
                                        name="lob"
                                        label="LOB"
                                        variant="outlined"
                                        margin="normal"
                                        required={true}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                    <TextField variant="outlined"
                                        margin="normal"
                                        id="subdomain-input"
                                        fullWidth
                                        name="subdomain"
                                        label="Custom Domain"
                                        required={true}
                                        onChange={handleChange}
                                        error={!isValidDomain}
                                        helperText={isValidDomain ? "" : "subdomain must consist of lower case alphanumeric characters, '-' or '.', and must start and end with an alphanumeric character"}
                                    />
                                    <Button style={{ marginTop: "1rem", marginBottom: "1rem" }} color="primary"
                                        startIcon={<FlightTakeoffIcon />}
                                        variant="contained"
                                        type="submit"
                                        disabled={isDisabled()}
                                        
                                    >On Board</Button>
                                    <Button
                                        style={{ marginTop: "1rem", marginBottom: "1rem", marginLeft: "1rem" }}
                                        variant="contained"
                                        component={Link}
                                        to="/"
                                    >Close</Button>
                                </form>
                            </div>
                        </Card>
                    </div>
                </Content>

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={shouldMessageAppear}
                    onClose={closeMessage}
                >
                    {isOnBoardSuccess ?
                        <MuiAlert severity="success">
                            You have successfully on boarded!
                            <br></br>
                            The newly created namespace is {newNamespace}.
                            <br></br>
                            Please check your email for further information.
                        </MuiAlert> :
                        <MuiAlert severity="error">
                            There was an error while trying to on board.
                            <br></br>
                            {message}
                        </MuiAlert>}
                </Snackbar>
            </Layout>
        </Layout>
    );
}

export default OnBoarding;
