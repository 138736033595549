import {Typography, Table, Divider, Row, Col, Tooltip} from 'antd';
import {useContext} from 'react';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CGAppContext from "../../AppContext";

const {Title, Text} = Typography;

type Props = {
    center?: any;
    configmaps?: any;
}

const chartColumns: any = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Version',
        dataIndex: 'version',
        key: 'version',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
    },
];

const featureColumns: any = [
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'Namespace',
        dataIndex: 'namespace',
        key: 'namespace',
    },
];

const chartDeploymentColumns: any = [
    {
        title: 'Time',
        dataIndex: 'time',
        key: 'time',
    },
    {
        title: 'Reason',
        dataIndex: 'reason',
        key: 'reason',
    },
    {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
    },
    {
        title: 'Info',
        dataIndex: 'info',
        key: 'info',
        render: (text: string, record: any) => (
            record.message && <Tooltip placement="topLeft" title={record.message}>
                <InfoOutlinedIcon/>
            </Tooltip>
        ),
    }
];

export default function Center(props: Props) {
    const appContext = useContext(CGAppContext);

    function getFeatures() {
        const result: any[] = []; // key is feature name, value is array of namespaces
        if (props.configmaps) {
            for (const feature of props.configmaps) {
                try {
                    const features = JSON.parse(feature.features);
                    for (const feat of features) {
                        const name: string = feat.name;
                        const description: string = feat.description;
                        const namespace: string = feature.namespace;
                        result.push({name, description, namespace});
                    }
                } catch(e) {
                    console.error(e);
                }
            }
        }

        return result.sort((a: any, b: any) => {
            return a.name.localeCompare(b.name);
        });
    }

    return (
        <Row className={"DetailsRow"} >
            <Col span={24}>
                <Divider orientation="left">Center Properties</Divider>
                <Row gutter={16}>
                    <Col span={6}>
                        <Title level={5}>Kubernetes Context</Title>
                        <Text>{appContext.cluster.context}</Text>
                    </Col>
                    <Col span={6}>
                        <Title level={5}>Name</Title>
                        <Text>{props.center?.name}</Text>
                    </Col>
                    <Col span={6}>
                        <Title level={5}>Namespace</Title>
                        <Text>{props.center?.namespace}</Text>
                    </Col>
                    <Col span={6}>
                        <Title level={5}>Creation Time</Title>
                        <Text>{new Date(props.center?.creationTimestamp).toString()}</Text>
                    </Col>
                </Row>
            </Col>
            <Col span={24}>
                <Divider orientation="left">Chart Deployment Status</Divider>
                <Table size="small" pagination={false} columns={chartDeploymentColumns}
                       dataSource={props.center?.conditions?.map((condition: any) => {
                           return {
                               key: `${condition.lastTransitionTime}-${condition.type}`,
                               time: `${new Date(condition.lastTransitionTime)}`,
                               reason: condition.reason,
                               type: condition.type,
                               message: condition.message,
                           }
                       })}/>
            </Col>
            <Col span={24}>
                <Divider orientation="left">Charts</Divider>
                <Table size="small" pagination={false} columns={chartColumns} dataSource={props.center?.charts?.map((chart: any) => {
                    return {
                        key: `${chart.namespace}-${chart.name}`,
                        name: chart.name,
                        version: chart.version,
                        status: chart['release-status-description'],
                    }
                })}/>
            </Col>
            <Col span={24}>
                <Divider orientation="left">Feature Toggles</Divider>
                <Table size="small" pagination={false} columns={featureColumns} dataSource={getFeatures().map((feature: any) => {
                    return {
                        key: `${feature.namespace}-${feature.name}`,
                        namespace: feature.namespace,
                        name: feature.name,
                        description: feature.description,
                    }
                })}/>
            </Col>
        </Row>
    );
}
