import config from './config';
import axios from "axios";
import { initializeAxiosMockAdapter, isMockEnabled, } from "./mock/mock.config";
import * as auth from "./services/auth.service";

let instance = axios.create({
    baseURL: config.api.invokeUrl,
    headers: {
        "Content-type": "application/json"
    }
});

let _headers: Array<{key: string, value: string}> = [];

export function setHeaders(headers: any[]) {
    _headers = headers;
}

// TODO uncomment this when server is ready
if (isMockEnabled()) {
    initializeAxiosMockAdapter(instance);
}
else {
    instance.interceptors.request.use(
        async (req) => {
            if (req.baseURL?.includes('dev.') || req.baseURL?.includes('api.')) {
                const token = auth.getAccessToken();
                if (token) {
                    for (const header of _headers) {
                        req.headers[header.key] = header.value;
                    };
                    req.headers['Authorization'] = `Bearer ${token}`;
                }
            }

            return req;
        },
        (err) => {
            // TODO:
            console.error(err);
        }
    );
}

export const axiosInstance = instance;
