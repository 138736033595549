import React from 'react';
import ClusterBadge from "../../components/ClusterBadge/ClusterBadge";
import { Layout, Menu } from 'antd';
import Divider from "@material-ui/core/Divider";
import { GATEWAYS } from '../../utils';
import { IGateway } from '../ResourceManagement/ResourceManagement';

const { Sider } = Layout;
const { SubMenu } = Menu;

export interface ResourceView {
    id: string;
    name: string;
    icon: React.ReactNode;
    data: any[];
    configurableProps: string[];
    editableProps: string[];
}

type Props = {
    clusterIconPath: string;
    cluster: any;
    clusterConnected: boolean;
    list: ResourceView[];
    currentView: string;
    onClickListItem: (name: string, filterItems: any[]) => void;
    onSetGateway: (name: string) => void;
    gateways?: IGateway[];
    selectedGateway: string;
}

export default function LeftPane(props: Props) {
    const handleListItemClick = (name: string) => {
        props.onClickListItem(name, [])
    }

    const handleGatewayClick = (name: string) => {
        props.onSetGateway(name);
        props.onClickListItem(GATEWAYS, []);
    }

    const [collapsed, setCollapsed] = React.useState(false);
    const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);

    React.useEffect(() => {
        if (props.selectedGateway) {
            setSelectedKeys([]);
        }
    }, [props.selectedGateway]);

    return (
        <Sider theme={"light"} collapsible collapsed={collapsed} onCollapse={() => setCollapsed(!collapsed)}>
            <div style={{ padding: "8px" }} className="logo">
                <ClusterBadge small={collapsed} avatarPath="/static/images/ccslogo.png"
                    connected={props.clusterConnected} />
                {!collapsed && <div>
                    <div>Cluster Name</div>
                    <div style={{
                        color: "rgba(0, 0, 0, 0.54)",
                        fontSize: "12px"
                    }}>{props.cluster.context}
                    </div>
                    {props.cluster.lob && <div>
                        <div>LoB</div>
                        <div style={{
                            color: "rgba(0, 0, 0, 0.54)",
                            fontSize: "12px"
                        }}>{props.cluster.lob}</div>
                    </div>}
                    {props.cluster.namespace && <div>
                        <div>Namespace</div>
                        <div style={{
                            color: "rgba(0, 0, 0, 0.54)",
                            fontSize: "12px"
                        }}>{props.cluster.namespace}</div>
                    </div>}
                </div>
                }
            </div>
            <Divider />

            <Menu selectedKeys={selectedKeys} defaultSelectedKeys={[props.list[0].id]} mode="inline">
                {props.list.map(item => {
                    if (item.name === GATEWAYS) {
                        return <SubMenu key={item.id} icon={item.icon} title={<span>{item.name}</span>}>
                            {props.gateways?.map((gateway: IGateway) => {
                                return <Menu.Item
                                    key={`${gateway.namespace}-${gateway.name}`}
                                    onClick={(e) => {
                                        handleGatewayClick(gateway.name)
                                        setSelectedKeys([e.key]);
                                    }}
                                >{gateway.name}</Menu.Item>
                            })
                            }
                        </SubMenu>
                    }
                    else {
                        return <Menu.Item onClick={() => {
                            setSelectedKeys([item.id]);
                            return handleListItemClick(item.name);
                        }} key={item.id} icon={item.icon}>
                            {item.name}
                        </Menu.Item>
                    }
                }
                )}
            </Menu>
        </Sider>
    );
}

