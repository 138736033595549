import _ from "lodash";
import { Tag } from 'antd';
import {
  ClusterOutlined,
} from '@ant-design/icons';
export function renderList(listArray: any, record: any, index: number) {

  return <div>
    {_.map(listArray, (gateway: string, index: number) => {
      return <Tag key={index} icon={<ClusterOutlined />} color={"default"}> {gateway} </Tag>
    })}
  </div>;
}

